<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('return-details') }}
        <v-spacer></v-spacer>
        <back-button></back-button
      ></v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
        <v-card outlined>
          <v-card-title>
            <div v-if="!loading" class="d-flex flex-column">
              <span>{{ $t('name') }} : {{ orderItem(returns).name }}</span>
              <span>{{ $t('region') }} : {{ orderItem(returns).region }}</span>
              <span
                >{{ $t('status') }} :
                <v-chip color="info">
                  {{ statues.find((v) => v.key == returns.status).name }}
                </v-chip></span
              >
              <span>{{ $t('note') }} : {{ returns.note }}</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="returns.status == 0"
              large
              @click="acceptReturn"
              color="warning"
              :loading="loading"
              :disabled="loading"
            >
              <v-icon class="mx-1">mdi-page-next-outline</v-icon>
              {{ $t('accept') }}
            </v-btn>
          </v-card-title>
        </v-card>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h2 class="mb-2">{{ $t('products') }}</h2>
            <v-data-table
              disable-pagination
              :items="products"
              hide-default-footer
              :loading="loading"
              :headers="productsHead"
            >
              <template v-slot:item.batchNo="{ item }">
                <v-autocomplete
                  class="mt-5"
                  outlined
                  :label="$t('batches')"
                  v-if="returns.status == 1"
                  :items="item.batches"
                  item-text=""
                  v-model="
                    returnToWarehouse.returnsProduct.find(
                      (v) => v.returnProductId == item.id
                    ).productBatchId
                  "
                  item-value="id"
                  :loading="loading"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      color="grey"
                      @click="data.select"
                      @click:close="
                        () => {
                          returnToWarehouse.returnsProduct.find(
                            (v) => v.returnProductId == item.id
                          ).productBatchId = null;
                        }
                      "
                    >
                      No. {{ data.item.batchNo }}
                      <small class="mx-1">
                        {{
                          $service.formatDate(new Date(data.item.expire), false)
                        }}</small
                      >
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          No. {{ data.item.batchNo }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            $service.formatDate(
                              new Date(data.item.expire),
                              false
                            )
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          QTY:
                          {{
                            $service.formatCurrency(data.item.totalNumberOfUnit)
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="returns.status == 1" cols="6">
            <v-textarea
              v-if="returns.status == 1"
              outlined
              :label="$t('note')"
              v-model="returnToWarehouse.note"
              :loading="loading"
            ></v-textarea>
          </v-col>
          <v-col v-if="returns.status == 1" cols="6">
            <div class="d-flex justify-end">
              <v-btn x-large @click="returnProductsToWarehouse" color="primary">
                <v-icon>mdi-content-save</v-icon>
                {{ $t('return-to-warehouse') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-container fluid class="" v-if="!loading">
        <div class="d-flex justify-space-between px-3">
          <div class="d-felx flex-column align-center">
            <div class="d-flex align-center">
              <p>{{ $t('total-amounts') }}:</p>
              <p class="grey lighten-1 pa-1 mx-2 rounded">
                {{ $service.formatCurrency(totalPrice) }}
              </p>
              <p>IQD</p>
            </div>
            <div class="d-flex align-center">
              <p>{{ $t('total-dis') }}:</p>
              <p class="grey lighten-1 pa-1 mx-2 rounded">
                {{ $service.formatCurrency(totalDiscount) }}
              </p>
              <p>IQD</p>
            </div>
            <div class="d-flex align-center">
              <p>{{ $t('net-amounts') }}:</p>
              <p class="grey lighten-1 pa-1 mx-2 rounded">
                {{ $service.formatCurrency(totalPriceAfterDiscount) }}
              </p>
              <p>IQD</p>
            </div>
            <div class="d-flex align-center">
              <p>{{ $t('new-net-amounts') }}:</p>
              <p class="grey lighten-1 pa-1 mx-2 rounded">
                {{ $service.formatCurrency(totalPriceAfterReturn) }}
              </p>
              <p>IQD</p>
            </div>
          </div>
        </div>
      </v-container>
    </v-card>
    <v-card class="transparent">
      <v-card-title> {{ $t('attachment') }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            v-for="attachement in returns.attachements"
            :key="attachement.id"
            md="4"
          >
            <v-card outlined class="d-flex flex-column" color="grey">
              <v-card-title>
                {{ attachement.name }}
                <v-spacer></v-spacer>
                <v-btn color="info" text @click="$htmlToPaper(attachement.id)">
                  <v-icon> mdi-printer </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text :id="attachement.id">
                <img
                  :src="attachement.path"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                  "
                  class="img-fluid"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import BackButton from "../../../../components/BackButton.vue";
export default {
  components: { BackButton },
  data() {
    return {
      returns: {},
      receivingDialog: false,
      productsHead: [
        { text: this.$t('products'), value: "product.tradeName" },
        { text: this.$t('batchs'), value: "batchNo" },
        { text: this.$t('qty-0'), value: "order.qty" },
        { text: this.$t('bonus'), value: "order.bonus" },
        { text: this.$t('price'), value: "order.price" },
        { text: this.$t('discount'), value: "order.discount" },
        { text: this.$t('return-qty'), value: "qty" },
        { text: this.$t('dosage-form'), value: "product.productDosageFormName" },
        { text: this.$t('Doctor'), value: "order.doctor.fullName" },
        { text: this.$t('doctor-deal'), value: "order.doctorDeal" },
      ],
      products: [],
      prefix: "",
      statues: [],
      loading: true,
      order: {
        products: [],
      },
      batches: [],
      returnToWarehouse: {
        note: "",
        returnsProduct: [],
      },
    };
  },
  methods: {
    async returnProductsToWarehouse() {
      this.loading = true;
      try {
        await this.$http.put(
          "/Returns/returnToWarehouse/" + this.$route.params.id,
          this.returnToWarehouse
        );
        this.getReturn(this.$route.params.id);
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    async acceptReturn() {
      this.loading = true;
      try {
        await this.$http.delete("/Returns/done/" + this.$route.params.id);
        this.getReturn(this.$route.params.id);
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    async getProductBatch(id, search = null) {
      try {
        let res = await this.$http.get("/ProductBatches", {
          params: {
            ProductId: id,
            Search: search,
          },
        });
        return res.data.data;
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
        return [];
      }
    },
    orderItem(item) {
      item = item.returnProducts[0];
      if (item.pharmacyOrderProduct !== null) {
        this.prefix = "pharmacy";
        return {
          type: "pharmacy",
          order: item.pharmacyOrderProduct,
          name: item.pharmacy.fullName,
          region: item.pharmacy.regionName,
          status: item.pharmacyOrderProduct.status,
        };
      } else if (item.doctorOrderProduct !== null) {
        this.prefix = "doctor";
        return {
          type: "doctor",
          order: item.doctorOrderProduct,
          name: item.doctor.fullName,
          region: item.doctor.regionName,
          status: item.doctorOrderProduct.status,
        };
      } else if (item.repositoryOrderProduct) {
        this.prefix = "repository";
        return {
          type: "repository",
          order: item.repositoryOrderProduct,
          name: item.repository.fullName,
          region: item.repository.regionName,
          status: item.repositoryOrderProduct.status,
        };
      } else {
        return {
          type: "unknown",
          order: item.id,
        };
      }
    },
    getOrder(id) {
      this.loading = true;
      this.$http.get(this.prefix + "Orders/" + id).then((response) => {
        this.order = response.data.result;
        this.loading = false;
      });
    },
    getReturn(id) {
      this.loading = true;
      this.$http.get("/Returns/" + id).then((res) => {
        this.returns = res.data.result;
        let products = this.returns.returnProducts;
        this.products = [];
        this.returnToWarehouse.returnsProduct = [];
        products.forEach(async (item) => {
          if (item.pharmacyOrderProduct !== null) {
            this.prefix = "pharmacy";
          } else if (item.doctorOrderProduct !== null) {
            this.prefix = "doctor";
          } else if (item.repositoryOrderProduct) {
            this.prefix = "repository";
          } else {
            this.prefix = "unknown";
          }
          this.returnToWarehouse.returnsProduct.push({
            returnProductId: item.id,
            productBatchId: "",
          });
          this.products.push({
            id: item.id,
            product: item[this.prefix + "OrderProduct"].product,
            batches: await this.getProductBatch(
              item[this.prefix + "OrderProduct"].product.id
            ),
            order: item[this.prefix + "OrderProduct"],
            ...item,
          });

          this.getOrder(this.returns.orderId);
        });
        this.loading = false;
      });
    },
    receiving() {
      this.receivingDialog = false;
    },
  },
  created() {
    this.statues = [
      { key: 0, name: "Pending" },
      { key: 1, name: "Done" },
      { key: 2, name: "Return To Warehouse" },
    ];
    this.getReturn(this.id);
  },
  computed: {
    id() {
      return this.$route.params.id;
    },

    totalDiscount() {
      let discount = 0;
      if (!this.loading) {
        this.order.products.forEach((item) => {
          if (item.approved == true) {
            discount += item.discount * item.qty;
          }
        });
      }
      return discount;
    },
    totalPrice() {
      let total = 0;

      if (!this.loading) {
        this.order.products.forEach((item) => {
          if (item.approved == true) {
            total += item.realUintPrice * item.qty;
          }
        });
      }
      return total;
    },
    totalPriceAfterDiscount() {
      let discount = 0;
      let totalPrice = 0;

      if (!this.loading) {
        this.order.products.forEach((product) => {
          if (product.approved == true) {
            discount += product.discount * product.qty;
            totalPrice += product.price * product.qty;
          }
        });
      }
      return totalPrice - discount;
    },
    totalPriceAfterReturn() {
      let total = 0;
      this.returns.returnProducts.forEach((itemReturned) => {
        this.order.products.forEach((item) => {
          total +=
            item.realUintPrice * item.qty -
            item.discount -
            ((item.realUintPrice * item.qty - item.discount) /
              (item.qty + item.bonus)) *
              itemReturned.qty;
        });
      });

      return total;
    },
  },
};
</script>
