<template>
  <div>
    <h1 class="mb-3">Incentives</h1>
    <!-- use dialog -->
    <add
      :dialog="dialog"
      @updated="
        (_) => {
          this.dialog = _;
          getIncentives();
        }
      "
    />

    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="4">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label for="comp">{{ $t("select-company") }}</label>
          <v-autocomplete
            class="mt-2"
            v-model="company"
            :items="companies"
            item-text="name"
            :label="$t('select-company')"
            item-value="id"
            :loading="loading"
            :no-data-text="$t('no-data')"
            solo
            return-object
            @change="getIncentives()"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" style="padding-top: 0">
          <v-chip-group
            v-model="status"
            @change="getIncentives()"
            :loading="loading"
            active-class="success"
          >
            <v-chip
              large
              v-for="(item, index) in statues"
              :key="index"
              filter
              :value="item.key"
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $t("incentives") }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="incentives"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.created="{ item }">
                  {{ $service.formatDate(new Date(item.created)) }}
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
// import ./Dialog.vue
import Add from "./add.vue";

export default {
  components: {
    Add,
  },
  data() {
    return {
      loading: false,
      options: {
        Search: "",
        CompanyId: this.$store.state.company.id,
        OfficeId: this.$store.state.userInfo.office.id,
        Status: "",
        PageIndex: 1,
        PageSize: 10,
      },
      status: 0,
      companies: [],
      company: {},
      statues: [],
      total: 0,
      dialog: false,
      incentives: [],
      headers: [
        { text: this.$t("value"), value: "value" },
        { text: this.$t("note"), value: "note" },
        { text: this.$t("user"), value: "user.fullName" },
        { text: "", value: "pay", sortable: false },
      ],
    };
  },
  methods: {
    getIncentives() {
      this.loading = true;
      if (this.status !== this.statues.length) {
        this.options.Status = this.status;
      } else {
        this.options.Status = "";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/incentives" + filter.replace("?&", "?"))
        .then((incentives) => {
          this.total = incentives.data.count;
          this.incentives = incentives.data.data;
          for (let i = 0; i < this.incentives.length; i++) {
            Object.keys(this.incentives[i]).forEach((key) => {
              if (typeof this.incentives[i][key] == "number") {
                this.incentives[i][key] =
                  "IQD " +
                  this.$service.formatCurrency(this.incentives[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getIncentives();
      },
      deep: true,
    },
  },
  async created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.loading = false;
        this.getIncentives();
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
};
</script>
