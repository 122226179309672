<template>
  <div>
    <add-study
      :dialog="dialog"
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getStudies();
        }
      "
    />
    <preview-study
      :dialog="preview"
      @updated="
        (_) => {
          preview = _;
        }
      "
    />

    <v-row v-if="product != null">
      <v-col cols="12">
        <v-row v-if="loading">
          <v-col v-for="i in 8" :key="i" cols="12" md="3">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
        <template v-if="!loading">
          <v-row v-if="studies.length > 0">
            <v-col cols="3" v-for="study in studies" :key="study.id">
              <v-sheet
                rounded=""
                class="d-flex flex-column"
                color="grey lighten-1"
                height="250"
              >
                <div
                  class="
                    mt-auto
                    pt-7
                    pb-2
                    text-center
                    align-center
                    flex-column
                    justify-center
                    d-flex
                  "
                >
                  <svg-icon
                    scale="5"
                    v-if="study.type == 'pdf' || study.type == 'docx'"
                    name="pdf"
                  />
                  <svg-icon
                    scale="5"
                    v-else-if="study.type == 'jpg' || study.type == 'png'"
                    name="photo"
                  />
                  <svg-icon scale="5" v-else name="files" />
                  <h5
                    class="
                      black--text
                      text--lighten-5 text-no-wrap
                      d-flex
                      flex-column
                    "
                  >
                    {{ study.name }}
                  </h5>
                </div>
                <sheet-footer>
                  <small
                    class="grey--text text--darken-4 font-weight-bold"
                    style="font-size: 11px"
                  >
                    {{ study.type }} -
                    {{ $service.formatDate(new Date(study.created)) }}
                  </small>

                  <div class="d-flex">
                    <v-btn
                      icon
                      small
                      color="grey darken-1"
                      @click="openPreview(study)"
                    >
                      <v-icon>mdi-eye </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      color="grey darken-1"
                      class="mx-1"
                      @click="downloadFile(study)"
                    >
                      <v-icon>mdi-download </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      color="error darken-1"
                      class="mx-1"
                      @click="confirm(study)"
                    >
                      <v-icon>mdi-trash-can </v-icon>
                    </v-btn>
                  </div>
                </sheet-footer>
              </v-sheet>
            </v-col>
          </v-row>
          <div v-else class="d-flex justify-center align-center mt-10">
            <h3>{{ $t("no-studies-uploaded") }}</h3>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="3" v-for="study in studies" :key="study.id">
        <v-sheet
          rounded=""
          class="d-flex flex-column"
          color="grey lighten-1"
          height="250"
        >
          <div
            class="
              mt-auto
              pt-7
              pb-2
              text-center
              align-center
              flex-column
              justify-center
              d-flex
            "
          >
            <svg-icon
              scale="5"
              v-if="study.type == 'pdf' || study.type == 'docx'"
              name="pdf"
            />
            <svg-icon
              scale="5"
              v-else-if="study.type == 'jpg' || study.type == 'png'"
              name="photo"
            />
            <svg-icon scale="5" v-else name="files" />
            <h5 class="text--lighten-5 text-no-wrap d-flex flex-column">
              {{ study.name }}
            </h5>
          </div>
          <sheet-footer>
            <small
              class="text--darken-4 font-weight-bold"
              style="font-size: 11px"
            >
              {{ study.type }} -
              <!-- format date -->
              {{ $service.formatDate(new Date(study.created)) }}
            </small>

            <div class="d-flex">
              <v-btn
                icon
                small
                color="grey darken-1"
                @click="openPreview(study)"
              >
                <v-icon>mdi-eye </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="grey darken-1"
                class="mx-1"
                @click="downloadFile(study)"
              >
                <v-icon>mdi-download </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="error darken-1"
                class="mx-1"
                @click="confirm(study)"
              >
                <v-icon>mdi-trash-can </v-icon>
              </v-btn>
            </div>
          </sheet-footer>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDelete" width="600">
      <v-card>
        <v-card-title>
          Are you sure you want to delete this study?
        </v-card-title>
        <v-card-text class="d-flex align-center justify-end mt-3">
          <v-btn
            @click="
              () => {
                confirmDelete = false;
                toDelte = {};
              }
            "
            color="success"
          >
            <v-icon left>mdi-check</v-icon>
            Cancel
          </v-btn>
          <v-btn @click="deleteStudy()" class="mx-2" color="error">
            <v-icon left>mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import Preview component
import PreviewStudy from "./Preview.vue";
import AddStudy from "./Add.vue";
export default {
  name: "Studies",
  props: {
    product: {
      type: String,
      default: null,
    },
  },
  components: {
    PreviewStudy,
    AddStudy,
    // A simple helper component
    SheetFooter: {
      functional: true,

      render(h, { children }) {
        return h(
          "v-sheet",
          {
            staticClass:
              "mt-auto  align-center  align-content-center justify-space-between d-flex px-2",
            props: {
              rounded: true,
              color: "white",
              height: 50,
              width: "100%",
            },
          },
          children
        );
      },
    },
  },
  data() {
    return {
      dialog: false,
      studies: [],
      loading: false,
      preview: false,
      toDelete: {},
      confirmDelete: false,
    };
  },
  methods: {
    confirm(study) {
      this.toDelete = study;
      this.confirmDelete = true;
    },
    getStudies() {
      this.loading = true;
      let params = {
        ProductId: this.product,
      };
      this.$http.get("/studies", params).then((response) => {
        this.studies = response.data.data;
        this.loading = false;
      });
    },
    deleteStudy() {
      //
      this.$http.delete(`/studies/delete/${this.toDelete.id}`).then(() => {
        this.getStudies();
        this.confirmDelete = false;
      });
    },
    downloadFile(study) {
      var anchor = document.createElement("a");
      anchor.href = study.path;
      anchor.download = study.path;
      anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    openPreview(study) {
      this.$store.commit("UPDATE_STUDY", study);
      this.preview = true;
    },
  },
  created() {
    this.getStudies();
  },
};
</script>
<style scoped></style>
