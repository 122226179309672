<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="my-5">
        Salary Calculation
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-form :loading="loading" ref="addSalary">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      :label="$t('note')"
                      v-model="inputs.note"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.amountPaid"
                      label="Paid Amounts"
                      @input="
                        inputs.amountPaid = $service.formatCurrency(
                          inputs.amountPaid
                        )
                      "
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addSalary">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {},
  data() {
    return {
      search: "",
      users: [],
      loading: false,
      inputs: {
        note: "",
        amountPaid: 0,
      },
    };
  },
  created() {},
  mounted() {},
  props: ["dialog"],
  methods: {
    async addSalary() {
      var val = this.$refs.addSalary.validate();

      if (val) {
        this.loading = true;
        var send = this.inputs;
        send.amountPaid = send.amountPaid.toString().replace(/,/g, "");

        this.$http
          .put("/Salaries/paySalary/" + this.$store.state.salary.id, send)
          .then(() => {
            this.$emit("added", !this.dialog);
            this.dialog = false;
          })
          .catch((e) => {
            if (e.response.data.message == "accepted") {
              this.$emit("added", !this.dialog);
              this.dialog = false;
            } else {
              if (e.response.data.statusCode == 202) {
                this.$emit("added", !this.dialog);
                this.dialog = false;
              } else {
                this.$store.commit("UPDATE_SNACKBAR", true);
                this.$store.commit(
                  "UPDATE_SNACKBAR_MESSAGE",
                  e.response.data.message
                );
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.$refs.addSalary.reset();
      this.$emit("updated", this.dialog);
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
