<template>
  <div>
    <div class="d-flex align-content-center justify-space-between">
      <h1 v-if="typeFrom == null" class="mb-3">
        {{ $t("forwarded-orders") }} ( {{ $t(type.replace("Orders", "")) }} )
      </h1>
      <!-- v-if="$service.hideFrom((v) => [v == 0])" -->
    </div>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col cols="12" md="2">
          <!-- v-debounce:500ms="getInvoices"  -->
          <v-text-field class="mr-1" v-model="options.Search" label="بحث حسب رقم الاوردر" hide-details=""  dense="" outlined="" filled="" />
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="3">
        <v-btn :disabled="selected.length  == 0" @click="openForward(item)" color="success" large block>
          Forward To Representatives
        </v-btn>
      </v-col>
        <!-- <v-col cols="3">
          <v-autocomplete
            solo
            :label="$t('order')"
            :items="orders"
            :search-input.sync="searchOrders"
            item-value="id"
            item-text="orderNO"
            :loading="loading"
            v-model="options.PharmacyOrderId"
            chips
          />
        </v-col> -->
        <v-col cols="12">
          <v-chip-group v-model="options.FullObtained" active-class="success">
            <v-chip filter label large :value="'skip'">{{
              $t("all")
            }}</v-chip>
            <v-chip filter label large :value="true">{{
              $t("full-obtained")
            }}</v-chip>
            <v-chip filter label large :value="false">{{
              $t("partial-obtained")
            }}</v-chip>
          </v-chip-group>
        </v-col>

        <v-col cols="12" class="mt-2">
          <!-- <pre dir="ltr"> {{selected}}</pre> -->
          <v-chip close @click:close="selected.splice(j, 1)" class="ma-1"  v-for="(order, j) in selected" :key="j">{{order.pharmacyOrder.pharmacy.fullName}} - {{order.pharmacyOrder.pharmacy.regionName}} ( {{order.pharmacyOrder.orderNO}} )</v-chip>
          <v-data-table
            show-select
            v-model="selected"
            disable-pagination
            :headers="getHeaders()"
            :items="invoices"
            :no-data-text="$t('no-data')"
            hide-default-footer
            :server-items-length="total"
            @click:row="
              (_) => {
                $router.push({
                  name: 'accounts-order',
                  params: {
                    type: type,
                    id: orderId(_),
                  },
                });
              }
            "
          >
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox v-if="statusS(item).key == 9"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
         </template>
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created), false) }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="statusS(item).key == 9"
                @click.stop="openForward(item)"
                class="mx-2"
                color="info"
                :disabled="selected.length > 0"
              >
                <v-icon>mdi-account-arrow-right-outline</v-icon>
                {{ $t("forward-to-representative") }}
              </v-btn>

              <v-chip v-else label>
                {{ statusS(item).name }}
              </v-chip>
              <v-btn
                v-if="statusS(item).key <= 10"
                @click.stop="openReceipt(item)"
                class="mx-2"
                color="primary"
              >
                <v-icon>mdi-eye</v-icon>
                {{ $t("receipt") }}
              </v-btn>
            </template>
            <template v-slot:item.totalPrice="{ item }">
              {{ $service.formatCurrency(item.totalPrice) }}
            </template>
            <template v-slot:item.fullObtained="{ item }">
              {{ item.fullObtained ? "Yes" : "No" }}
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="forwardRepDialog" width="600">
      <v-card>
        <v-card-title>
          {{ $t("forward-to-representative") }}
        </v-card-title>
        <v-card-text class="mt-3 ">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                outlined
                :label="$t('representative')"
                :no-data-text="$t('write-name-to-search')"
                :items="representatives"
                item-text="fullName"
                hide-details="auto"
                v-model="forwardRep.representativeId"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                :search-input.sync="searchRepresentative"
                item-value="id"
                :loading="loading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.fullName }}
                    <small class="mx-1">{{ data.item.userTypeName }}</small>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.userTypeName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
              <div class="d-flex align-center justify-end mt-5">
                <v-btn
                  @click="
                    () => {
                      forwardRepDialog = false;
                    }
                  "
                  outlined
                  color="error"
                >
                  <v-icon left>mdi-close</v-icon>
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  :disabled="forwardRep.representativeId == ''"
                  @click="forwardOrderToRep()"
                  class="mx-2"
                  color="success"
                >
                  <v-icon left>mdi-delete</v-icon>
                  {{ $t("forward") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <receipt
      @added="
        (_) => {
          receiptDialog = _;
          getInvoices();
        }
      "
      @updated="
        (_) => {
          receiptDialog = _;
        }
      "
      :prefix="prefix.replace('.', '')"
      :type="type"
      :dialog="receiptDialog"
    />
  </div>
</template>
<script>
import Pusher from "pusher-js";
import Receipt from "./Receipt.vue";
export default {
  components: {
    Receipt,
  },
  props: {
    typeFrom: {
      type: String,
      default: null,
    },
    fromId: {
      type: String,
      default: null,
    },
  },
  computed: {
    role() {
      return this.$store.state.roles[this.$store.getters.role];
    },
  },
  data() {
    return {
      selected: [],
      company: {},
      receiptDialog: false,
      options: {
        CompanyId: "",
        AgentId: "",
        Search: "",
        PageSize: 30,
        ProvinecId: "",
        PageIndex: 1,
        OfficeId: this.$store.state.userInfo.office.id,
        StartDate: "",
        PharmacyOrderId: "",
        FullObtained: "skip",
      },
      total: 0,
      invoices: [],
      type: "PharmacyOrders",
      status: [0, 1, 2, 3],
      loading: true,
      backStatus: [],
      forwardRep: {
        representativeId: "",
      },
      forwardRepDialog: false,
      toForward: {},
      prefix: "",
      statues: [],
      searchOrders: "",
      representatives: [],
      orders: [],
      searchRepresentative: "",
      // headers: ,
    };
  },
  mounted() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher("b4d809eca77d687d414a", {
      cluster: "ap2",
    });
    this.channel = this.pusher.subscribe("01orderChannelSecret");
    this.channel.bind("01orderEventSecret", (data) => {
      this.invoices.unshift(data);
    });
    if (this.typeFrom != null) {
      this.type = this.typeFrom;
    }
    this.$parent.$on("updateOrders", this.getInvoices());
  },
  created() {
    if (this.typeFrom != null) {
      this.type = this.typeFrom;
    }
    this.$http.get(`/${this.type}/statuses`).then((st) => {
      this.backStatus = st.data.result;
      this.loading = false;
      if (this.role >= 1) {
        this.options.CompanyId = this.$store.state.userInfo.userData.companyId;
      }
      this.$http.get("/Provinecs").then((com) => {
        this.provinecs = com.data.result;
        this.provinecs.unshift({ id: "", name: "All" });
      });
    });
  },
  methods: {
    selectAllToggle(props) {
       if(this.selected.length != this.desserts.length - this.disabledCount) {
         this.selected = [];
         const self = this;
         props.items.forEach(item => {
           if(!item.disabled) {
             self.selected.push(item);
           } 
         });
       } else this.selected = [];
     },
    openReceipt(item) {
      this.$store.commit("regions/updateRegion", item);
      this.receiptDialog = true;
    },
    forwardOrderToRep() {
      if(this.selected.length  > 0){
        this.forwardToManyRep()
      }else{
        this.forwardToOneRep()
      }
    },

    async forwardToManyRep(){
      this.loading = true;
      let type = this.type;
      if (type == "PharmacyOrders") {
        type = "Orders";
      }
      try {
        for (let i = 0; i < this.selected.length; i++) {
        try {
          await this.$http.post( type + "Forwarded/ForwardedToRepresentative/" + this.selected[i].id, this.forwardRep)
        } catch (e) {
          console.log(e);
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
          this.loading = false;
        }
      }
      } catch (err) {
        console.log(err);
      }finally {
        this.forwardRepDialog = false;
        this.getInvoices();
        this.selected = [];
        this.loading = false;
      }
      
    },
    forwardToOneRep(){
      this.loading = true;
      let type = this.type;
      if (type == "PharmacyOrders") {
        type = "Orders";
      }
      this.$http
        .post(
          type + "Forwarded/ForwardedToRepresentative/" + this.toForward.id,
          this.forwardRep
        )
        .then(() => {
          this.loading = false;
          this.forwardRepDialog = false;
          this.getInvoices();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    statusColor(item) {
      let status = this.backStatus.find((x) => x.key == item.status);
      if (status) {
        let colors = {
          0: "warning",
          1: "blue",
          3: "blue lighten-1",
          2: "error",
          4: "error lighten-1",
          5: "success",
          6: "error lighten-2",
          7: "success lighten-1",
        };
        return colors[status.key];
      } else {
        return "";
      }
    },

    getHeaders() {
      let name = "pharmacy.fullName";
      let prefix = "";
      if (this.type == "PharmacyOrders") {
        name = "pharmacyOrder.pharmacy.fullName";
        prefix = "pharmacyOrder.";
      } else if (this.type == "RepositoryOrders") {
        name = "repositoryOrder.repository.fullName";
        prefix = "repositoryOrder.";
      } else if (this.type == "DoctorOrders") {
        name = "doctorOrder.doctor.fullName";
        prefix = "doctorOrder.";
      }
      this.prefix = prefix;
      let region = "";
      if (this.type == "PharmacyOrders") {
        region = "pharmacyOrder.pharmacy.regionName";
      } else if (this.type == "RepositoryOrders") {
        region = "repositoryOrder.repository.regionName";
      } else if (this.type == "DoctorOrders") {
        region = "doctorOrder.doctor.regionName";
      }
      let headers = [
        {
          text: this.$t("no"),
          align: "start",
          // sortable: false,
          value: prefix + "orderNO",
        },
        { text: this.$t("obtained"), value: "fullObtained" },
        {
          text: this.$t(this.type.replace("Orders", "")),
          value: name,
        },
        {
          text: this.$t("region"),
          value: region,
        },
        { text: this.$t("price"), value: prefix + "totalPrice" },
      ];
      headers.push({
        text: this.$t("teamleader"),
        value: "teamLeaderFullName",
      });
      headers.push({
        text: this.$t("representative"),
        value: "representativeFullName",
      });
      headers.push({ text: this.$t("date"), value: "created" });
      headers.push({ text: this.$t("actions"), value: "actions" });
      return headers;
    },
    getInvoices() {
      this.loading = true;
      this.invoices = [];
      let type = this.type;
      if (type == "PharmacyOrders") {
        type = "OrdersForwarded";
      } else {
        type += "Forwarded";
      }
      if (this.role == 9) {
        this.options.TeamLeaderId = this.$store.state.userData.id;
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val != "skip" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get(`/${type}` + filter.replace("?&", "?"))
        .then((prod) => {
          this.invoices = prod.data.data;
          this.loading = false;
          this.total = prod.data.count;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    orderId(item) {
      if (this.type == "PharmacyOrders") {
        return item.pharmacyOrder.id;
      } else if (this.type == "RepositoryOrders") {
        return item.repositoryOrder.id;
      } else if (this.type == "DoctorOrders") {
        return item.doctorOrder.id;
      }
    },
    openForward(item) {
      this.toForward = item;
      this.forwardRepDialog = true;
    },
    statusS(item) {
      if (this.type == "PharmacyOrders") {
        return this.backStatus.find((x) => x.key == item.pharmacyOrder.status);
      } else if (this.type == "RepositoryOrders") {
        return this.backStatus.find(
          (x) => x.key == item.repositoryOrder.status
        );
      } else if (this.type == "DoctorOrders") {
        return this.backStatus.find((x) => x.key == item.doctorOrder.status);
      }
    },
    async getOrders(type, search = null) {
      try {
        this.loading = true;
        let filter = {};
        if (search != null) {
          filter.search = search;
        }
        let res = await this.$http.get(`/${type}`, {
          params: filter,
        });
        this.orders = res.data.data;
        this.loading = false;
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
        this.loading = false;
      }
    },
  },
  watch: {
    searchOrders() {
      this.getOrders(this.type, this.searchOrders);
      this.searchOrders = "";
    },
    typeFrom() {
      if (this.typeFrom != null) {
        this.type = this.typeFrom;
      }
      this.getHeaders();
      this.getOrders(this.type);
      this.options.PharmacyOrderId = null;
      this.getInvoices();
    },
    $route: {
      handler() {
        this.type = this.$route.params.type;
        if (this.typeFrom != null) {
          this.type = this.typeFrom;
        }
        this.getHeaders();
        this.getOrders(this.type);
        this.options.PharmacyOrderId = null;
        this.getInvoices();
      },
      immediate: true,
    },
    options: {
      handler: function() {
        this.getInvoices();
      },
      deep: true,
    },
    searchRepresentative() {
      if (
        this.searchRepresentative !== "" &&
        this.searchRepresentative !== null
      ) {
        this.loading = true;
        this.$http
          .get(
            "/Representatives?RepresentativeTypeId=a84e6408-4601-48d5-8d72-2d39b00d9833&Search=" +
              this.searchRepresentative
          )
          .then((res) => {
            this.representatives = res.data.data;
            this.loading = false;
          });
      }
    },
  },
};
</script>
