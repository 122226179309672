<template>
  <div>
    <salary-calculation
      :dialog="salaryCalculation"
      @updated="
        (_) => {
          salaryCalculation = _;
        }
      "
      @added="
        (_) => {
          salaryCalculation = _;
          getSalaries();
        }
      "
    />
    <Pay
      :dialog="paySalary"
      @updated="
        (_) => {
          paySalary = _;
        }
      "
      @added="
        (_) => {
          paySalary = _;
          getSalaries();
        }
      "
    />
    <h1 class="mb-3">Salaries</h1>
    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="3">
          <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          ><label for="comp">Select User</label>
          <v-autocomplete
            class="mt-2"
            clearable
            v-model="options.UserId"
            :items="users"
            :search-input.sync="searchUser"
            :loading="loading"
            :no-data-text=" 
              searchUser == '' && searchUser == null
                ? 'Write for search'
                : 'No data'
            "
            solo
            @change="getSalaries()"
            item-value="id"
            item-text="fullName"
            label="Select User"
          />
        </v-col>
        <v-col cols="3">
          <label for="comp">{{ $t('select-company') }}</label>
          <v-autocomplete
            class="mt-2"
            v-model="company"
            :items="companies"
            item-text="name"
            :label="$t('select-company')"
            item-value="id"
            :loading="loading"
            :no-data-text="$t('no-data')"
            solo
            return-object
            @change="getSalaries()"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="3">
          <label for="st">From Date</label>
          <v-menu
            class="mt-2"
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-detials="auto"
                solo
                v-model="options.StartDate"
                label="From Date"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="options.StartDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <label for="st">To Date</label>
          <v-menu
            class="mt-2"
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-detials="auto"
                solo
                v-model="options.EndDate"
                label="To Date"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="options.StartDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                @click="salaryCalculation = !salaryCalculation"
              >
                <v-icon>mdi-plus</v-icon>
                Pay salary
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="salaries"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.datePaid="{ item }">
                  {{ $service.formatDate(new Date(item.datePaid)) }}
                </template>
                <template v-slot:item.isPaid="{ item }">
                  {{ item.isPaid ? "Yes" : "No" }}
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import SalaryCalculation from "./SalaryCalculation.vue";
import Pay from "./Pay.vue";
export default {
  components: {
    SalaryCalculation,
    Pay,
  },
  data() {
    return {
      loading: false,
      users: [],
      options: {
        Search: "",
        // CompanyId: this.$store.state.company.id,
        UserId: "",
        CompanyId: null,
        StartDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * (60000 - 60000 * 250)
        )
          .toISOString()
          .substr(0, 10),
        EndDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        PageIndex: 1,
        PageSize: 10,
        OfficeId: this.$store.state.userInfo.office.id,
      },
      companies: [],
      total: 0,
      company: {},
      searchUser: "",
      salaryCalculation: false,
      paySalary: false,
      salaries: [],
      headers: [
        {
          text: this.$t('user'),
          align: "start",
          sortable: false,
          value: "user.userName",
        },

        { text: "Salary", value: "defaultSalary" },
        { text: "Paid Salary", value: "paidSalary" },
        { text: "Incentives", value: "totalIncentives" },
        { text: "Deductions", value: "totalDeductions" },
        // { text: "Drawn Amounts", value: "totalWithdrawnAmounts" },
        // { text: "Transfer", value: "totalTransferReceivables" },
        // { text: "Total", value: "totalSalary" },
        // { text: "Paid", value: "paidSalary" },
        { text: "Paid Date", value: "datePaid" },
        { text: this.$t('note'), value: "note" },
        { text: "", value: "pay", sortable: false },
      ],
    };
  },
  methods: {
    
    getSalaries() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/Salaries" + filter.replace("?&", "?"))
        .then((salaries) => {
          this.total = salaries.data.count;
          this.salaries = salaries.data.data;
          for (let i = 0; i < this.salaries.length; i++) {
            Object.keys(this.salaries[i]).forEach((key) => {
              if (typeof this.salaries[i][key] == "number") {
                this.salaries[i][key] =
                  "IQD " + this.$service.formatCurrency(this.salaries[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("UPDATE_SNACKBAR", true);
        });
    },
    pay(item) {
      this.$store.commit("UPDATE_SALARY", item);
      this.paySalary = true;
    },
  },
  watch: {
    options: {
      handler() {
        this.getSalaries();
      },
      deep: true,
    },
    searchUser() {
      if (this.searchUser !== "" && this.searchUser !== null) {
        this.loading = true;
        this.$http
          .get("/Users?Search=" + this.searchUser, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((res) => {
            this.users = res.data.data;
            this.loading = false;
          });
      }
    },
  },
 created() {
    // this.searchUser();
    this.getSalaries();
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
};
</script>
