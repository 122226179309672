<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span v-if="!isOne" class="text-h5">Produسct batches</span>
        <span v-if="isOne" class="text-h5"
          >
          {{ $t('product-batches') }}
          <span class="font-weight-bold mx-1">{{ productName }}</span>
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <label  for="comp">Select Company</label>
            <v-autocomplete
              class="mt-2"
              v-model="options.CompanyId"
              :items="companies"
              item-text="name"
              :label="$t('select-company')"
              item-value="id"
              clearable
              :loading="loading"
              :no-data-text="$t('no-data')"
              solo
              @change="getBatchs()"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label  for="comp">Select warehouse</label>
            <v-autocomplete
              class="mt-2"
              @change="getBatchs()"
              clearable
              @input.
              v-model="options.WarehouseId"
              :items="warehouses"
              :search-input.sync="searchWarehouse"
              :loading="loading"
              :no-data-text="
                searchWarehouse == '' && searchWarehouse == null
                  ? 'Write for search'
                  : 'No data'
              "
              solo
              item-value="id"
              item-text="name"
              label="Select warehouse"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label  for="comp">Select product</label>
            <v-autocomplete
              class="mt-2"
              @change="getBatchs()"
              clearable
              @input.
              v-model="options.ProductId"
              :items="products"
              :search-input.sync="searchProduct"
              :loading="loading"
              :no-data-text="
                searchProduct == '' && searchProduct == null
                  ? 'Write for search'
                  : 'No data'
              "
              solo
              item-value="id"
              item-text="tradeName"
              label="Select product"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label  for="se">Search </label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
disable-pagination
      :loading="loading"
      :headers="headers"
      :items="batches"
      dark
      hide-default-footer
      :server-items-length="total"
      :no-data-text="$t('no-data')"
      class="mt-5"
    >
      <template v-slot:item.expire="{ item }">
        <span dir="ltr">
          {{ formatDate(new Date(item.expire)) }}
        </span>
      </template>
      <template v-slot:item.numberOfBoxes="{ item }">
        {{ $service.formatCurrency(item.numberOfBoxes) }}
      </template>
      <template v-slot:item.numberOfUnitsPerBox="{ item }">
        {{ $service.formatCurrency(item.numberOfUnitsPerBox) }}
      </template>
      <template v-slot:item.singleBuyPrice="{ item }">
        {{ $service.formatCurrency(item.singleBuyPrice) }}IQD
      </template>
      <template v-slot:item.wholesaleUnitPrice="{ item }">
        {{ $service.formatCurrency(item.wholesaleUnitPrice) }}IQD
      </template>
    </v-data-table>
    <div class="d-flex justify-center">
      <v-pagination
        v-model="options.PageIndex"
        :length="parseInt(total / options.PageSize) + 1"
      />
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      product: {},
      isOne: false,
      batches: [],
      company: "",
      total: 0,
      companies: [],
      searchWarehouse: "",
      warehouses: [],
      products: [],
      searchProduct: "",
      options: {
        CompanyId: "",
        PageSize: 10,
        PageIndex: 1,
        Search: "",
        WarehouseId: "",
      },
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "batchNo",
        },

        { text: "Trade Name", value: "product.tradeName" },
        { text: "Scientific Name", value: "product.scientificName" },
        { text: "Boxes", value: "numberOfBoxes" },
        { text: "Per box", value: "numberOfUnitsPerBox" },
        { text: "Single $", value: "singleBuyPrice", sortable: true },
        { text: "Wholesale", value: "wholesaleUnitPrice", sortable: true },
        { text: "Expire", value: "expire", sortable: true },
      ],
    };
  },
  methods: {
    formatDate,
    getBatchs() {
      let params = {};
      if (this.id !== null) {
        this.isOne = true;
        params = { ProductId: this.id };
      }
      Object.keys(this.options).forEach((e) => {
        if (this.options[e] !== null && this.options[e] !== "") {
          params[e] = this.options[e];
        }
      });
      this.$http
        .get("/ProductBatches", { params: params })
        .then((res) => {
          this.batches = res.data.data;
          if (this.batches.length > 0) {
            this.product = this.batches[0].product;
          }
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((e) => {
          
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    getWarehouses() {
      let search = "";
      if (this.searchWarehouse == "" && this.searchWarehouse == null) {
        search = `Search=${this.searchWarehouse}`;
      }
      this.$http
        .get("/Warehouses" + search, {
          params: {
            CompanyId: this.$store.state.userInfo.userData.companyId,
            OfficeId: this.$store.state.userInfo.office.id,
          },
        })
        .then((war) => {
          this.warehouses = war.data.data;
          this.warehouses.unshift({ id: "", name: "All" });
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    getProducts() {
      let search = "";
      if (this.searchProduct == "" && this.searchProduct == null) {
        search = `Search=${this.searchProduct}`;
      }
      this.$http
        .get("/Products" + search)
        .then((war) => {
          this.products = war.data.data;
          this.products.unshift({ id: "", tradeName: "All" });
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  computed: {
    id() {
      let id = this.$route.params.id;
      if (id == undefined) {
        id = null;
      }
      return id;
    },
    productName() {
      if (this.product.name !== undefined) {
        return this.product.scientificName;
      } else {
        return "";
      }
    },
  },
  watch: {
    "options.Search"() {
      this.getBatchs();
    },
    searchWarehouse() {
      this.getWarehouses();
    },
    searchProduct() {
      this.getProducts();
    },
    options: {
      handler() {
        this.getBatchs();
      },
      deep: true,
    },
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        // this.options.CompanyId = this.$store.state.company.id;
        this.companies.unshift({ id: "", name: "All" });
        // this.getWarehouses();
        this.getProducts();
        this.getBatchs();
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
};
</script>