<template>
  <div>
    <div class="d-flex align-content-center justify-center" v-if="loading">
      <v-progress-circular size="55" indeterminate></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12">
        <v-card width="100%">
          <v-card-title>
            {{ $t("details") }}
            <v-spacer></v-spacer>
            <back-button></back-button>
            <v-btn
              v-if="order.status == 7"
              @click="forwardDialog = true"
              class="mx-2"
              color="info"
            >
              <v-icon>mdi-account-arrow-right-outline</v-icon>
              {{ $t("foward-to-teamleader") }}
            </v-btn>
            <v-btn
              depressed
              v-if="cancelCheck"
              :class="deletStatus ? 'mof-dis-btn' : ''"
              :disabled="deletStatus"
              color="error"
              @click="cancelOrder()"
            >
              {{ $t("cancel-order") }}
            </v-btn>
            <v-btn
              class="mx-3"
              v-if="order.status == 1 || order.status == 0"
              :class="deletStatus ? 'mof-dis-btn' : ''"
              :disabled="deletStatus"
              color="error"
              @click="deletOrder()"
            >
              {{ $t("delete-order") }}
            </v-btn>
          </v-card-title>
          <v-card-text title="r">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex flex-column">
                <h3>
                  {{ $t("created-by") }}: {{ order.userCreated.fullName }}
                </h3>
                <h3 class="mt-2">
                  {{ $t(type.replace("Orders", "")) }} : {{ name }}
                </h3>
                <h3 class="mt-2">{{ $t("region") }} : {{ regionName }}</h3>
                <h3 class="mt-2">
                  {{ $t("order-payment") }} :
                  {{ order.isCash ? $t("cash") : $t("delayed") }}
                </h3>
                <!-- <h3 class="mt-2">Order Note : {{ order.note }}</h3> -->
                <!-- <template v-for="(product, i) in order.products">
                  <h3 class="mt-2" :key="i">
                    {{ product.product.tradeName }} {{ $t('note') }} : {{ product.note }}
                  </h3>
                </template> -->
              </div>
              <!-- {{order.products}} -->
              <h3>
                {{ $t(typeText) }} {{ $t("status") }} :
                <v-chip label color="info" text-color="white">
                  {{ status == null ? "None" : status }}
                </v-chip>
              </h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-2">
          <v-card-title>
            {{ $t("products") }}
            <v-spacer></v-spacer>
            <v-btn
              dense
              v-if="order.status > 5 && order.status != 6"
              :to="{
                name: 'print-order',
                params: {
                  id: orderId,
                  type: type,
                  product: 'all',
                },
              }"
              target="_blank"
              color="info"
              outlined
            >
              <v-icon>mdi-printer</v-icon>
              {{ $t("print-all-products") }}
            </v-btn>
            <div v-if="order.status < 6">
              <v-btn @click="confirmReject = true" large color="error">
                <f-icon icon="times-circle" />
                {{ $t("reject-all-products") }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <table class="mt-1">
              <thead>
                <tr>
                  <th>{{ $t("products") }}</th>
                  <th>{{ $t("batch-no") }}</th>
                  <th>{{ $t("dose") }}</th>
                  <th>{{ $t("dosage-form") }}</th>
                  <th>{{ $t("cash") }}</th>
                  <th style="width: 6%">{{ $t("qty") }}</th>
                  <th style="width: 6%">{{ $t("bonus") }}</th>
                  <th style="width: 6%">{{ $t("u-iqd") }}</th>
                  <th style="width: 6%">{{ $t("discount") }}</th>
                  <th style="width: 6%">{{ $t("price") }}</th>
                  <th v-if="type == 'PharmacyOrders'">{{ $t("deal") }}</th>
                  <th v-if="type == 'PharmacyOrders'">{{ $t("Doctor") }}</th>
                  <th v-if="type == 'PharmacyOrders'">
                    {{ $t("Doctor") }} {{ $t("deal") }}
                  </th>
                  <th v-if="type != 'RepositoryOrders'">
                    {{ $t("transit-to") }}
                  </th>
                  <th v-if="order.status < 5">{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, i) in order.products" :key="i">
                  <td>{{ product.product.tradeName }}</td>
                  <td>
                    <div v-if="order.status >= 7">
                      <v-chip
                        class="mx-2"
                        label
                        v-for="batch in product.batches"
                        :key="batch.id"
                      >
                        {{ batch.batchNo }}
                      </v-chip>
                    </div>
                    <v-autocomplete
                      class="mt-1"
                      outlined
                      :label="$t('batches')"
                      v-if="order.status == 5"
                      :no-data-text="$t('write-name-to-search')"
                      :items="product.batches"
                      item-text=""
                      multiple
                      v-model="
                        done[
                          done.indexOf(
                            done.find(
                              (v) =>
                                v[typeText.toLowerCase() + 'OrderProductId'] ==
                                product.id
                            )
                          )
                        ].productBatchs
                      "
                      item-value="id"
                      :loading="loading"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          color="grey"
                          @click="data.select"
                          @click:close="
                            remove(
                              data.item,
                              done.indexOf(
                                done.find(
                                  (v) =>
                                    v[
                                      typeText.toLowerCase() + 'OrderProductId'
                                    ] == product.id
                                )
                              )
                            )
                          "
                        >
                          No. {{ data.item.batchNo }}
                          <small class="mx-1">
                            {{
                              $service.formatDate(
                                new Date(data.item.expire),
                                false
                              )
                            }}</small
                          >
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>
                              No. {{ data.item.batchNo }}</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{
                                $service.formatDate(
                                  new Date(data.item.expire),
                                  false
                                )
                              }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              QTY:
                              {{
                                $service.formatCurrency(
                                  data.item.totalNumberOfUnit
                                )
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    {{ product.product.dose }}
                  </td>
                  <td>
                    {{ product.product.productDosageFormName }}
                  </td>
                  <td>
                    <span>
                      {{ product.cash ? $t("cash") : $t("delayed") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="!isEdit(product)">
                      {{ product.qty }}
                    </span>
                    <v-text-field
                      :label="$t('qty')"
                      v-else
                      id="id"
                      v-model="editObj.qty"
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <span v-if="!isEdit(product)">
                      {{ product.bonus }}
                    </span>
                    <v-text-field
                      :label="$t('bonus')"
                      v-else
                      id="id"
                      v-model="editObj.bonus"
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <span v-if="!isEdit(product)">
                      {{ $service.formatCurrency(product.price) }}
                    </span>
                    <v-text-field
                      :label="$t('price')"
                      v-else
                      id="id"
                      v-model="editObj.price"
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <span v-if="!isEdit(product)">
                      {{ $service.formatCurrency(product.discount) }}
                      <span class="d-none">
                        {{ forceUpdate }}
                      </span>
                    </span>
                    <v-text-field
                      :label="$t('discount')"
                      v-else
                      id="id"
                      v-model="editObj.discount"
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    {{
                      $service.formatCurrency(
                        product.realUintPrice * (product.qty + product.bonus) -
                          product.discount * product.qty
                      )
                    }}
                  </td>
                  <td v-if="type == 'PharmacyOrders'">
                    <span>
                      {{ product.isDoctorDeal ? true : false }}
                    </span>
                  </td>

                  <td v-if="type == 'PharmacyOrders'">
                    <template>
                      <span v-if="product.doctor">{{
                        product.doctor.fullName
                      }}</span>
                    </template>
                  </td>
                  <td v-if="type == 'PharmacyOrders'">
                    <span v-if="!isEdit(product)">
                      {{ product.doctorDeal }}
                    </span>
                    <v-text-field
                      :label="$t('deal')"
                      v-else
                      id="id"
                      v-model="product.doctorDeal"
                      outlined
                    ></v-text-field>
                  </td>
                  <td v-if="type != 'RepositoryOrders'">
                    <router-link
                      v-if="product.transitTo != null"
                      :to="{
                        name: 'store-detials',
                        params: {
                          id: product.transitTo.id,
                        },
                      }"
                    >
                      {{ product.transitTo.fullName }}
                    </router-link>
                  </td>
                  <td class="align-center">
                    <div class="d-flex align-center">
                      <div class="d-flex align-center" v-if="!isEdit(product)">
                        <v-btn-toggle v-if="order.status < 5" dense>
                          <v-btn
                            @click="addToCart(product, false)"
                            color="error"
                            :outlined="isApproved(product.id)"
                          >
                            <!-- v-if="isApproved(product.id)" -->
                            <v-icon
                              :color="
                                !isApproved(product.id) ? 'white' : 'error'
                              "
                            >
                              mdi-close-box-outline
                            </v-icon>
                            <span
                              :class="
                                !isApproved(product.id)
                                  ? 'white--text'
                                  : 'error--text'
                              "
                              >{{ $t("reject") }}</span
                            >
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="addToCart(product, true)"
                            :outlined="!isApproved(product.id)"
                            text-color="primary"
                          >
                            <v-icon
                              :color="
                                isApproved(product.id) ? 'white' : 'success'
                              "
                            >
                              mdi-check
                            </v-icon>
                            <span
                              :class="
                                isApproved(product.id)
                                  ? 'white--text'
                                  : 'success--text'
                              "
                              >{{ $t("accept") }}</span
                            >
                          </v-btn>
                        </v-btn-toggle>
                        <div v-if="order.status < 5" class="mx-1">
                          <v-btn
                            v-if="
                              type != 'RepositoryOrders' &&
                                product.transitTo == null
                            "
                            color="warning"
                            @click="openTranit(product)"
                          >
                            <v-icon>mdi-keyboard-return</v-icon>
                            {{ $t("transit-to") }}
                          </v-btn>
                        </div>
                        <v-btn
                          dense
                          v-if="order.status > 6 && !isApproved(product.id)"
                          :to="{
                            name: 'print-order',
                            params: {
                              id: orderId,
                              type: type,
                              product: product.id,
                            },
                          }"
                          target="_blank"
                          small
                          color="info"
                          text
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                        <v-chip
                          v-if="order.status > 6"
                          :color="!isApproved(product.id) ? 'success' : 'error'"
                        >
                          <v-icon>
                            {{
                              !isApproved(product.id)
                                ? "mdi-check"
                                : "mdi-close"
                            }}
                          </v-icon>
                          {{
                            !isApproved(product.id)
                              ? $t("approved")
                              : $t("rejected")
                          }}
                        </v-chip>
                      </div>
                      <v-btn
                        v-if="order.status < 3"
                        class="mx-1"
                        @click="toggleEdit(product)"
                        :color="isEdit(product) ? 'error' : 'primary'"
                        outlined
                      >
                        <v-icon>mdi-pencil</v-icon>
                        {{ isEdit(product) ? $t("cancel") : $t("edit") }}
                      </v-btn>
                      <v-btn
                        v-if="isEdit(product)"
                        @click="saveEdit(product)"
                        color="primary"
                        outlined
                      >
                        <v-icon>mdi-check-all</v-icon>
                        {{ $t('save') }}
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
          <v-card-text>
            <div>
              <div class="d-flex align-end flex-column justify-end">
                <v-btn
                  v-if="order.status < 7"
                  color="success"
                  x-large
                  @click="saveOrder"
                  :disabled="!minApproved"
                >
                  <v-icon> mdi-content-save </v-icon>
                  {{ $t('save') }} {{ $t('order') }}</v-btn
                >
                <h3 v-if="!minApproved && order.status < 7">
                  {{ $t('at-least-1-products-must-be-approved') }}
                </h3>
              </div>
              <div
                v-if="type == 'PharmacyOrders'"
                class="d-flex justify-center mt-1"
              >
                <template v-for="(doc, i) in Object.keys(doctors)">
                  <div
                    :key="`doc${i}`"
                    style="width: 75%"
                    class="d-flex align-center"
                  >
                    <h3 class="mx-2 text-center">
                      {{ doc }}
                    </h3>
                    <table style="width: 100%">
                      <thead>
                        <th>{{ $t('products') }}</th>
                        <th>{{ $t('deal') }}</th>
                        <th>{{ $t('total-deal') }}</th>
                      </thead>
                      <tbody>
                        <tr v-for="(prod, i) in doctors[doc]" :key="`prod` + i">
                          <!-- <td></td> -->
                          <td>{{ prod.product.tradeName }}</td>
                          <td>{{ prod.doctorDeal }}</td>
                          <td>
                            {{
                              `${prod.doctorDeal} * ${
                                prod.qty
                              } = ${prod.doctorDeal * prod.qty}`
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>
              <div class="d-flex justify-space-between">
                <div class="d-felx flex-column align-center">
                  <div class="d-flex align-center">
                    <p>{{ $t('total-amounts') }}:</p>
                    <p class="grey lighten-1 pa-2 mx-2 rounded">
                      {{ $service.formatCurrency(totalPrice) }}
                    </p>
                    <p>IQD</p>
                  </div>
                  <div class="d-flex align-center">
                    <p>{{ $t('total-dis') }}:</p>
                    <p class="grey lighten-1 pa-2 mx-2 rounded">
                      {{ $service.formatCurrency(totalDiscount) }}
                    </p>
                    <p>IQD</p>
                  </div>
                  <div class="d-flex align-center">
                    <p>{{ $t('net-amounts') }}:</p>
                    <p class="grey lighten-1 pa-2 mx-2 rounded">
                      {{ $service.formatCurrency(totalPriceAfterDiscount) }}
                    </p>
                    <p>IQD</p>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmReject" width="600">
      <v-card>
        <v-card-title>
         {{ $t('are-you-sure-you-want-to-reject-this-order') }}
        </v-card-title>
        <v-card-text class="d-flex align-center justify-end mt-3">
          <v-btn
            @click="
              () => {
                confirmReject = false;
              }
            "
            outlined
            color="error"
          >
            <v-icon left>mdi-close</v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn @click="reject()" class="mx-2" color="error">
            <v-icon left>mdi-delete</v-icon>
            {{ $t('reject') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="transitDialog" width="600">
      <v-card>
        <v-card-title> {{ $t('select-store-to-transit') }} </v-card-title>
        <v-card-text>
          <v-autocomplete
            class="mt-1"
            solo
            :rules="[(v) => !!v || $t('this-field-is-requird')]"
            :label="$t('store')"
            no-data-text="Write name to search..."
            :items="stores"
            item-text="fullName"
            v-model="transit.repositoryId"
            small-chips
            :search-input.sync="searchStore"
            item-value="id"
            :loading="loading"
          />
          <div class="d-flex align-center justify-end mt-3">
            <v-btn
              outlined
              @click="
                () => {
                  transitDialog = false;
                }
              "
              color="primary"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn @click="transitTo()" class="mx-2" color="error">
              <v-icon left>mdi-alert</v-icon>
              {{ $t('transit-to') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="forwardDialog" width="600">
      <v-card>
        <v-card-title>
          {{ $t('forward-this-order-to-teamleader') }}
        </v-card-title>
        <v-card-text class="mt-3 ">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                outlined
                :label="$t('teamleader')"
                :no-data-text="$t('write-name-to-search')"
                :items="teamleaders"
                item-text="fullName"
                hide-details="auto"
                v-model="forward.teamLeaderId"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                :search-input.sync="searchTeamleader"
                item-value="id"
                :loading="loading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.fullName }}
                    <small class="mx-1">{{ data.item.userTypeName }}</small>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.userTypeName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
              <div class="d-flex align-center justify-end mt-5">
                <v-btn
                  @click="
                    () => {
                      forwardDialog = false;
                    }
                  "
                  outlined
                  color="error"
                >
                  <v-icon left>mdi-close</v-icon>
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  :disabled="forward.teamLeaderId == ''"
                  @click="forwardOrder()"
                  class="mx-2"
                  color="success"
                >
                  <v-icon left>mdi-delete</v-icon>
                  {{ $t('forward') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackButton from "../../../../components/BackButton.vue";
export default {
  components: { BackButton },
  name: "Order",
  data() {
    return {
      loading: false,
      order: {
        status: 0,
        userCreated: {
          fullName: "",
        },
        phamarcy: {
          name: "",
        },
      },
      forwardDialog: false,

      forwardRepDialog: false,
      transitDialog: false,
      editObj: {},
      forward: {
        teamLeaderId: "",
      },
      forwardRep: {
        representativeId: "",
      },
      type: "PharmacyOrders",
      orderId: this.$route.params.id,
      approvedProducts: [],
      forceUpdate: 0,
      headers: [
        { text: "Item", value: "productTradeName" },
        { text: "Batch No.", value: "bno" },
        { text: "Exp Date", value: "expDate" },
        { text: "QTY", value: "qty" },
        { text: "Status", value: "approved" },
        { text: this.$t('actions'), value: "action" },
      ],
      office: {},

      stores: [],
      doctors: {},
      done: [],
      confirmReject: false,
      searchStore: "",
      transit: {
        note: "",
        repositoryId: "",
        doctorOrderProductId: "",
        pharmacyOrderProductId: "",
      },
      transitProduct: {},
      storeId: "",
      searchTeamleader: "",
      teamleaders: [],
      representatives: [],
      searchRepresentative: "",
      doctor_list: "",
      deletStatus: false,
    };
  },
  methods: {
    async deletOrder() {
      this.deletStatus = true;
      this.$http
        .delete(this.type + "/delete/" + this.$route.params.id)
        .then(() => {
          this.deletStatus = false;
          history.back();
        })
        .catch(() => {
          this.deletStatus = false;
        });
    },
    async getDoctorList() {
      this.loading = true;
      this.$http.get("/Doctors").then((res) => {
        this.doctor_list = res.data.data;
        console.log("this.doctor_list");
        console.log(this.doctor_list);
      });
    },
    async cancelOrder() {
      this.deletStatus = true;
      this.$http
        .delete(this.type + "/cancel/" + this.orderId)
        .then(() => {
          this.deletStatus = false;
          history.back();
        })
        .catch(() => {
          this.deletStatus = false;
          alert("Cancel Order Faild");
        });
    },
    forwardOrder() {
      this.loading = true;
      let type = this.type;
      if (type == "PharmacyOrders") {
        type = "Orders";
      }
      this.$http
        .post(
          type + "Forwarded/ForwardedToTeamLeader/" + this.orderId,
          this.forward
        )
        .then(() => {
          this.loading = false;
          this.forwardDialog = false;
          this.getInvoice();
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },

    saveEdit(product) {
      this.editObj.doctorDeal = product.doctorDeal;
      this.loading = true;
      this.$http
        .put(`/${this.type}/put/` + product.id, this.editObj)
        .then(() => {
          this.loading = false;
          this.getInvoice();
        })
        .catch((e) => {
          this.loading = false;
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    toggleEdit(product) {
      this.order.products.find(
        (p) => p.id == product.id
      ).edit = !this.order.products.find((p) => p.id == product.id).edit;
      if (this.order.products.find((p) => p.id == product.id).edit) {
        this.editObj = {
          qty: product.qty,
          price: product.price,
          bonus: product.bonus,
          discount: product.discount,
          dealValue: product.dealValue,
          note: "",
        };
      } else {
        this.editObj = {
          qty: 0,
          price: 0,
          bonus: 0,
          discount: 0,
          dealValue: 0,
          note: "",
        };
      }

      this.forceUpdate++;
      //
    },
    isEdit(product) {
      return this.order.products.find((p) => p.id == product.id).edit;
    },
    openTranit(product) {
      this.transitProduct = product;
      if (this.type == "PharmacyOrders") {
        this.transit.pharmacyOrderProductId = product.id;
      } else {
        this.transit.doctorOrderProductId = product.id;
      }
      this.transitDialog = true;
    },
    transitTo() {
      this.loading = true;
      this.$http
        .put(this.type + "/isTransit/" + this.orderId, this.transit)
        .then(() => {
          this.loading = false;
          this.transitDialog = false;
          this.getInvoice();
        });
    },
    getDoctors() {
      let doctors = {};
      this.order.products.forEach((product) => {
        if (doctors[product.doctor.fullName] == undefined) {
          doctors[product.doctor.fullName] = [];
        }
        doctors[product.doctor.fullName].push(product);
      });
      this.doctors = doctors;
      return doctors;
    },
    remove(item, id) {
      console.log(this.done[id].productBatchs, item.id);
      this.done[id].productBatchs.splice(
        this.done[id].productBatchs.indexOf(item.id),
        1
      );
      //  .productBatches.splice(item.id, 1);
    },
    reject() {
      this.$http
        .put(`/${this.type}/changeStatus/${this.orderId}`, {
          note: "string",
          status: 6,
          approvedProducts: [],
        })
        .then(() => {
          this.$router.push({ name: "accounts-orders" });
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    isApproved(id) {
      if (this.order.status == 0) {
        if (this.approvedProducts.filter((v) => v.id == id).length > 0) {
          return this.approvedProducts.filter((v) => v.id == id)[0].approved;
        }
      } else {
        if (this.order.products.filter((v) => v.id == id).length > 0) {
          return !this.order.products.filter((v) => v.id == id)[0].approved;
        }
      }
      return false;
    },
    async addToCart(product, approved) {
      this.removeFromCart(product);
      this.approvedProducts.push({
        ...product,
        approved: approved,
      });

      //
    },
    // remove from cart
    removeFromCart(product) {
      this.approvedProducts = this.approvedProducts.filter(
        (v) => v.id != product.id
      );
    },
    getInvoice() {
      this.loading = true;
      this.$http.get(`/${this.type}/` + this.orderId).then((response) => {
        this.order = response.data.result;
        console.log("this.order");
        console.log(this.order);
        if (this.order.status == 5) {
          // this.approvedProducts = this.order.products;
          const prods = [];
          for (let i = 0; i < this.order.products.length; i++) {
            if (this.order.products[i].approved) {
              this.$http
                .get("/products/" + this.order.products[i].product.id)
                .then((batches) => {
                  this.order.products[
                    i
                  ].batches = batches.data.result.productBatches.filter(
                    (v) => v.totalNumberOfUnit > 0
                  );
                });
              prods.push(this.order.products[i]);
              let pu = {
                productBatchs: [],
              };
              pu[
                this.typeText.toLowerCase() + "OrderProductId"
              ] = this.order.products[i].id;
              this.done.push(pu);
            }
          }
          this.order.products = prods;
        } else {
          for (let i = 0; i < this.order.products.length; i++) {
            this.order.products[i].edit = false;
            // this.order.products[i].note = "";
          }
        }
        this.loading = false;
        if (this.type == "PharmacyOrders") {
          this.getDoctors();
        }
      });
    },
    saveOrder() {
      let body;
      if (this.order.status == 5) {
        body = [];
        for (var j = 0; j < this.done.length; j++) {
          body[j] = {
            productBatchs: [],
          };
          body[j][this.typeText.toLowerCase() + "OrderProductId"] = this.done[
            j
          ][this.typeText.toLowerCase() + "OrderProductId"];
          for (let i = 0; i < this.done[j].productBatchs.length; i++) {
            body[j].productBatchs[i] = {
              productBatchId: this.done[j].productBatchs[i],
            };
          }
        }
        this.$http
          .post(`/${this.type}/orderDone/` + this.order.id, body)
          .then(() => {
            this.$router.push({ name: "accounts-orders" });
          })
          .catch((e) => {
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.errors.join("<br />")
            );
            // this.$store.commit("UPDATE_SNACKBAR", true);
          });
      } else {
        body = {
          note: "xx",
          status: 5,
          approvedProducts: [],
        };
        for (let i = 0; i < this.approvedProducts.length; i++) {
          body.approvedProducts.push({
            id: this.approvedProducts[i].id,
            approved: this.approvedProducts[i].approved,
            note: this.approvedProducts[i].note,
          });
        }
        this.$http
          .put(`/${this.type}/changeStatus/${this.orderId}`, body)
          .then(() => {
            this.$router.push({ name: "accounts-orders" });
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message
            );
          });
      }
    },
  },
  mounted() {
    this.getDoctorList();
    console.log("this.userPermition");
    console.log(this.userPermition);
  },
  computed: {
    cancelCheck() {
      if (this.userPermition == "Owner" && this.order.status == 7) {
        return true;
      } else {
        return false;
      }
    },
    minApproved() {
      if (this.order.status == 5) {
        // console.log('done', this.done);
        return (
          this.done.filter((item) => item.productBatchs.length > 0).length > 0
        );
      } else {
        if (this.approvedProducts.length > 0) {
          return (
            this.approvedProducts.filter((product) => product.approved == 1)
              .length > 0
          );
        }
      }
      return false;
    },
    totalDiscount() {
      let discount = 0;
      this.order.products.forEach((item) => {
        if (item.approved == true) {
          discount += item.discount * item.qty;
        }
      });
      return discount;
    },
    totalPrice() {
      let total = 0;
      this.order.products.forEach((item) => {
        if (item.approved == true) {
          total += item.price * item.qty;
        }
      });
      return total;
    },
    totalPriceAfterDiscount() {
      let discount = 0;
      let totalPrice = 0;
      this.order.products.forEach((product) => {
        if (product.approved == true) {
          discount += product.discount * product.qty;
          totalPrice += product.realUintPrice * (product.qty + product.bonus);
        }
      });
      return totalPrice - discount;
    },
    typeText() {
      return this.type.replace("Orders", "");
    },
    name() {
      if (this.type == "PharmacyOrders") {
        return this.order.pharmacy.fullName;
      } else if (this.type == "RepositoryOrders") {
        return this.order.repository.fullName;
      }
      return this.order.doctor.fullName;
    },
    regionName() {
      if (this.type == "PharmacyOrders") {
        return this.order.pharmacy.regionName;
      } else if (this.type == "RepositoryOrders") {
        return this.order.repository.regionName;
      }
      return this.order.doctor.regionName;
    },
    status() {
      if (this.type == "PharmacyOrders") {
        return this.order.pharmacy.status;
      } else if (this.type == "RepositoryOrders") {
        return this.order.repository.status;
      }
      return this.order.doctor.status;
    },
  },
  watch: {
    $route: {
      handler() {
        this.type = this.$route.params.type;

        this.getInvoice();
      },
      immediate: true,
    },
    searchTeamleader() {
      if (this.searchTeamleader !== "" && this.searchTeamleader !== null) {
        this.loading = true;
        this.$http
          .get("/teamleaders?Search=" + this.searchTeamleader)
          .then((res) => {
            this.teamleaders = res.data.data;
            this.loading = false;
          });
      }
    },

    searchRepresentative() {
      if (
        this.searchRepresentative !== "" &&
        this.searchRepresentative !== null
      ) {
        this.loading = true;
        this.$http
          .get(
            "/Representatives?RepresentativeTypeId=a84e6408-4601-48d5-8d72-2d39b00d9833&Search=" +
              this.searchRepresentative
          )
          .then((res) => {
            this.representatives = res.data.data;
            this.loading = false;
          });
      }
    },
    searchStore(val) {
      if (val != null && val != "") {
        this.$http
          .get("/Repositories", {
            params: {
              Search: val,
            },
          })
          .then((res) => {
            this.stores = res.data.data;
          });
      }
    },
  },
  created() {
    this.type = this.$route.params.type;
    if (this.orderId !== undefined) {
      this.loading = true;
      this.$http.get("/teamleaders").then((res) => {
        this.teamleaders = res.data.data;
        this.loading = false;
      });
      this.$http
        .get("/Offices/" + this.$store.state.userInfo.office.id)
        .then((res) => {
          this.office = res.data.result;
        })
        .finally(() => {
          this.getInvoice();
          this.$http.get("/Repositories").then((res) => {
            this.stores = res.data.data;
          });
        });
    } else {
      // go back
      this.$router.go(-1);
    }
  },
};
</script>
<style scoped>
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: #000000 !important;
}

.mof-dis-btn {
  cursor: no-drop;
  opacity: 0.6;
}

header {
  display: none !important;
}
table {
  width: 100%;
  border-collapse: none;
  border-spacing: 0;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}
thead {
  background-color: #f5f5f5;
  color: #ffffff;
}
th {
  border: 1px solid #ffffff;
  padding: 2px;
  color: #000000;
}
td {
  border: 1px solid #ffffff;
  padding: 5px;
}
.box {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 1px !important;
}
p {
  font-size: 20px;
  font-weight: bold !important;
}
.box {
  /* width: 100%; */
  /* border: 1px solid #ffffff;
  border-radius: 1px !important; */
}
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  background: #f5f5f5;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>
