<template>
<div>
    <v-card color="transparent" class="transparent" flat>
        <v-card-title>
            <h3>
                {{ $t("sales-rep-wallet") }}
            </h3>
        </v-card-title>
        <v-container fluid>
            <v-row align="center">
                <v-col cols="3">
                    <v-chip-group mandatory active-class="success" v-model="type">
                        <v-chip v-for="item in types" :key="item.value" :value="item.value" color="secondary" large filter label>
                            {{ item.text }}
                        </v-chip>
                    </v-chip-group>
                </v-col>
                <v-col cols="4">
                    <label for="se">Select {{ type }} </label>
                    <v-autocomplete v-if="type == 'Pharmacy'" clearable v-model="options.pharmacyId" :items="pharmacies" :search-input.sync="searchPharmacy" :loading="loading" :no-data-text="
                searchPharmacy == '' && searchPharmacy == null
                  ? 'Write to search ...'
                  : 'No data..'
              " solo filled large item-value="id" chips item-text="fullName" :label="'Select ' + type" hide-details="auto">
                    </v-autocomplete>
                    <v-autocomplete v-else-if="type == 'Repository'" clearable v-model="options.repositoryId" :items="repositories" :search-input.sync="searchRepository" :loading="loading" :no-data-text="
                searchRepository == '' && searchRepository == null
                  ? 'Write to search ...'
                  : 'No data..'
              " solo filleds item-value="id" large chips item-text="fullName" :label="'Select ' + type" hide-details="auto">
                    </v-autocomplete>
                    <v-autocomplete v-else hide-detials="auto" clearable chips v-model="options.doctorId" :items="doctors" :search-input.sync="searchDoctor" :loading="loading" large :no-data-text="
                searchDoctor == '' && searchDoctor == null
                  ? 'Write to search ...'
                  : 'No data..'
              " solo filleds item-value="id" :label="'Select ' + type" item-text="fullName" hide-details="auto" />
                </v-col>
                <v-col cols="3">
                    <label for="se">ٌRegion</label>
                    <v-autocomplete v-model="options.regionId" :label="$t('region')" hide-details="auto" chips filled solo :items="regions" item-text="name" item-value="id" :search-input.sync="searchRegion"></v-autocomplete>
                </v-col>
                <!-- <v-col cols="2">
            <v-autocomplete chips
            filled
            solo item-text="text" item-value="text" @input="getDues()" v-model="options.pageSize" :items="[{text:50},{text:100},{text:1000}]" hide-details="auto"/>
          </v-col> -->
                <v-col></v-col>
                <v-col cols="4">
                    <h3>
                        {{ $t("select-representative") }}
                    </h3>
                    <v-text-field solo :label="$t('search-representative')" :loading="loading" hide-details="" v-model="searchRepresentative"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <v-text-field v-debounce:500ms="getDues" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                          <h3>From</h3>
                          <v-text-field solo v-model="options.StartDate"    hide-details="auto"  append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="options.StartDate" @input="()=> {getDues(), menu = false}" locale="ar"></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                  <!-- <v-text-field v-debounce:500ms="getDues" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                          <h3>To</h3>
                          <v-text-field solo v-model="options.EndDate" hide-details="auto"  append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="options.EndDate" @input="()=> {getDues(), menu2 = false}" locale="ar"></v-date-picker>
                  </v-menu>
              </v-col>

                <v-col>
                    <v-chip-group column v-model="options.ReceivingUserId" active-class="success">
                        <v-chip v-for="rep in representatives" :key="rep.id" :value="rep.id" color="secondary" large filter label>
                            {{ rep.fullName }}
                        </v-chip>
                    </v-chip-group>
                </v-col>

                <v-col cols="12">
                    <h3 v-if="dues.length > 0">TOTAL RECEIPTS for current page: <span class="primary--text">( {{$service.formatCurrency(dues.map(item => item.receiptAmount).reduce((prev, next) => prev + next))}} )</span></h3>

                </v-col>

                <v-col cols="12">
                    <v-data-table :items-per-page="options.PageSize" :headers="getHeaders()" :items="dues" :no-data-text="$t('no-data')" hide-default-footer :loading="loading">
                        <template v-slot:item.created="{ item }">
                            <span dir="ltr">
                                {{ $service.formatDate(new Date(item.created), false) }}
                            </span>
                        </template>
                        <template v-slot:item.dateReceipt="{ item }">
                            <span dir="ltr">
                                {{ $service.formatDate(new Date(item.created), false) }}
                            </span>
                        </template>
                        <template v-slot:item.id="{ index }">
                            <span dir="ltr">
                                {{ index + 1 }}
                            </span>
                        </template>
                        <template v-slot:item.receiptAmount="{ item }">
                            <div>{{$service.formatCurrency(item.receiptAmount)}}</div>
                        </template>
                        <template v-slot:item.no="{ item, index }">
                            <div>{{index + 1}}</div>
                        </template>
                        <template v-slot:item.zeroing="{ item }">
                            <v-btn color="error" v-if="!item.zeroing" @click="openConfirmDialog(item)">{{ $t("zeroing") }}</v-btn>
                            <v-btn color="success" icon large v-else>
                                <v-icon>mdi-check-all</v-icon>
                                {{ $t("zeroed") }}
                            </v-btn>
                        </template>
                    </v-data-table>
                    <div class="d-flex justify-center">
                        <v-pagination v-model="options.PageIndex" :length="parseInt(total / options.PageSize) + 1" />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
    <v-dialog persistent v-model="confirmDialog" width="600">
        <v-card :loading="loading" outlined>
            <v-card-title class="my-5">
                {{ $t("confirm-zeroing") }}
                <v-spacer></v-spacer>
                <v-btn @click="confirmDialog = !confirmDialog" text color="error">
                    <f-icon icon="times-circle" />
                </v-btn>
            </v-card-title>
            <v-card-text>
                {{ $t("are-you-sure-you-want-to-zeroing") }}
            </v-card-text>
            <v-card-actions class="my-5">
                <v-spacer></v-spacer>
                <v-btn @click="confirmDialog = !confirmDialog" outlined color="error">
                    {{ $t("cancel") }}
                </v-btn>
                <v-btn @click="zeroing(selectedItem)" color="success">
                    {{ $t("confirm") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            type: "",
            loading: false,
            menu: false,
            menu2: false,
            types: [{
                    value: "Pharmacy",
                    text: this.$t("Pharmacy"),
                },
                {
                    value: "Repository",
                    text: this.$t("Repository"),
                },
                {
                    value: "Doctor",
                    text: this.$t("Doctor"),
                },
            ],

            total: 0,
            representatives: [],
            selectedItem: null,
            confirmDialog: false,
            dues: [],
            searchRepresentative: "",
            searchDoctor: "",
            searchPharmacy: "",
            searchRepository: "",
            searchRegion: "",
            pharmacies: [],
            repositories: [],
            doctors: [],
            regions: [],
            options: {
                PageSize: 50,
                PageIndex: 1,
                ReceivingUserId: "",
                regionId: "",
                StartDate: "",
                EndDate: ""
            },
        };
    },
    methods: {
        getHeaders() {
            let type = this.type.toLowerCase();
            let headers = [
                {
                    text: "#",
                    value: "no",
                },
                {
                    text: this.$t("list-no"),
                    value: type + "Order.orderNO",
                },
                {
                    text: this.$t("representative"),
                    value: "receivingUser.fullName",
                },
                {
                    text: this.$t("receipt"),
                    value: "receiptAmount",
                },
                {
                    text: this.$t(type),
                    value: type + "Order." + type + ".fullName",
                },
                {
                    text: this.$t("receipt-date"),
                    value: "dateReceipt",
                },
                {
                    text: this.$t("zeroing"),
                    value: "zeroing",
                },
                {
                    text: this.$t("created"),
                    value: "created",
                },
                {
                    text: this.$t('region'),
                    value: type + "Order." + type + ".regionName",
                }
            ];
            return [
                ...headers,
            ];
        },
        async getRepresentatives(search = null) {
            try {
                this.loading = true;
                let res = await this.$http.get("/representatives", {
                    params: {
                        search,
                    },
                });
                this.representatives = res.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getPharmacies(search = null) {
            try {
                this.loading = true;
                let res = await this.$http.get("/pharmacies", {
                    params: {
                        search,
                    },
                });
                this.pharmacies = res.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getDoctors(search = null) {
            try {
                this.loading = true;
                let res = await this.$http.get("/Doctors", {
                    params: {
                        search,
                    },
                });
                this.Doctor = res.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getRegions(search = null) {
            try {
                this.loading = true;
                let res = await this.$http.get("/regions", {
                    params: {
                        search,
                    },
                });
                this.regions = res.data.result.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getRepositories(search = null) {
            try {
                this.loading = true;
                let res = await this.$http.get("/repositories", {
                    params: {
                        search,
                    },
                });
                this.repositories = res.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        openConfirmDialog(item) {
            this.selectedItem = item;
            this.confirmDialog = true;
        },
        zeroing(item) {
            this.loading = true;
            this.$http
                .delete(`/${this.type}FinancialDues/zeroing/${item.id}`)
                .then(() => {
                    this.getDues();

                    this.confirmDialog = false;
                })
                .catch((e) => {
                    this.confirmDialog = false;
                    this.loading = false;
                    this.$store.commit("UPDATE_SNACKBAR", true);
                    this.$store.commit(
                        "UPDATE_SNACKBAR_MESSAGE",
                        e.response.data.message
                    );
                });
        },
        getDues() {
            this.loading = true;

            this.$http
                .get(`/${this.type}FinancialDues/collectDues`, {
                    params: this.options,
                })
                .then((res) => {
                    this.total = res.data.result.count;
                    this.dues = res.data.result.data;
                    this.loading = false;
                });
        },
    },
    created() {
        this.getRepresentatives();
        this.getDues();
        if (this.type == "Pharmacy") {
            this.getPharmacies();
        }
        if (this.type == "Repository") {
            this.getRepositories();
        }
        if (this.type == "Doctor") {
            this.getDoctors();
        }
        this.getRegions();
    },
    watch: {
        searchRepresentative: function (val) {
            this.getRepresentatives(val);
        },
        options: {
            handler: function () {
                this.getDues();
            },
            deep: true,
        },
        type: function () {
            this.getDues();
            if (this.type == "Pharmacy") {
                this.getPharmacies();
            }
            if (this.type == "Repository") {
                this.getRepositories();
            }
            if (this.type == "Doctor") {
                this.getDoctors();
            }
        },
        searchDoctor: function (val) {
            this.getDoctors(val);
        },
        searchPharmacy: function (val) {
            this.getPharmacies(val);
        },
        searchRepository: function (val) {
            this.getRepositories(val);
        },
        searchRegion: function (val) {
            this.getRegions(val);
        },
    },
};
</script>
