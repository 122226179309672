<template>
  <div>
    <h1 class="mb-3">{{ $t("payment-vouchers") }}</h1>
    <!-- use dialog -->
    <add
      :dialog="dialog"
      @updated="
        (_) => {
          this.dialog = _;
          getpaymentVouchers();
        }
      "
    />

    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="3">
          <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="3" >
          <label for="comp">{{ $t("company") }}</label>
          <v-autocomplete
            class="mt-2"
            clearable
            v-model="options.CompanyId"
            :items="companies"
            :loading="loading"
            solo
            filled
            item-value="id"
            item-text="name"
            :label="$t('company')"
            hide-details="auto"
          />
        </v-col> -->
        <v-col cols="3">
          <label for="se">{{ $t('start-date') }}</label>
          <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            class="white"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-detials="auto"
                solo
                filled
                class="mt-2"
                v-model="options.startDate"
                :label="$t('start-date')"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="options.startDate">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    options.startDate = '';
                  }
                "
              >
                {{ $t('reset') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <label for="se">{{ $t('end-date') }}</label>
          <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            class="white"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-detials="auto"
                solo
                filled
                class="mt-2"
                v-model="options.endDate"
                :label="$t('end-date')"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="options.endDate">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    options.endDate = '';
                  }
                "
              >
                {{ $t('reset') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $t("payment-vouchers") }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="paymentVouchers"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.created="{ item }">
                  {{ $service.formatDate(new Date(item.created)) }}
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
// import ./Dialog.vue
import Add from "./add.vue";

export default {
  components: {
    Add,
  },
  data() {
    return {
      loading: false,
      options: {
        Search: "",
        CompanyId: null,
        // CompanyId: this.$store.state.company.id,
        OfficeId: this.$store.state.userInfo.office.id,
        PageIndex: 1,
        startDate:null,
        endDate:null,
        PageSize: 50,
      },
      companies: [],
      company: {},
      statues: [],
      total: 0,
      status: 0,
      dialog: false,
      paymentVouchers: [],
      headers: [
        { text: this.$t('no'), value: "voucherNo" },
        { text: this.$t('amount'), value: "amount" },
        { text: this.$t('description'), value: "description" },
        
        {text:this.$t('created-by'),value:'createdUserFullName'},
        { text: this.$t('created'), value: "created" },
      ],
    };
  },
  methods: {
    getpaymentVouchers() {
      this.loading = true;
      if (this.status !== this.statues.length) {
        this.options.Status = this.status;
      } else {
        this.options.Status = "";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/paymentVouchers" + filter.replace("?&", "?"))
        .then((paymentVouchers) => {
          this.paymentVouchers = paymentVouchers.data.data;
          this.total = paymentVouchers.data.count
          for (let i = 0; i < this.paymentVouchers.length; i++) {
            Object.keys(this.paymentVouchers[i]).forEach((key) => {
              if (
                typeof this.paymentVouchers[i][key] == "number" &&
                key !== "voucherNo"
              ) {
                this.paymentVouchers[i][key] =
                  "IQD " +
                  this.$service.formatCurrency(this.paymentVouchers[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getpaymentVouchers();
      },
      deep: true,
    },
  },
  async created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.loading = false;
        this.getpaymentVouchers();
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
};
</script>
