<template>
  <div>
  
      <v-row align="center" class="mb-3">
          <v-col cols="12" md="3"> 
              <!-- <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.from" label="من" outlined hide-details="auto" filled append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="filter.from" @input="()=> {getItems(), menu = false}" locale="ar"></v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="3">
              <!-- <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.to" label="إلى" outlined hide-details="auto" filled append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="filter.to" @input="()=> {getItems(), menu2 = false}" locale="ar"></v-date-picker>
              </v-menu>
          </v-col>
      </v-row>
      <v-card outlined>
          <v-data-table :items="items" :loading="loading" :headers="headers" hide-default-footer>
  
          </v-data-table>
      </v-card>
      <br />
      <v-card outlined dir="rtl">
        <h2 class="text-right ma-4">الحسابات الختامية</h2>
          <v-container fluid class="mt-3 d-flex flex-wrap">
  
              
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalProductsInWarehouse)}}</h3>
                      </v-chip> :مشتريات المنتجات المتوفره في المخزن
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalProductsPurchases)}}</h3>
                      </v-chip> :مشتريات المنتجات المباعة
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalProductsPurchases + summary.totalProductsInWarehouse)}}</h3>
                      </v-chip> :مشتريات المنتجات الكلية
                  </h3>
              </v-alert>
  
              <!-- <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalProductsPurchases + summary.totalProductsInWarehouse)}}</h3>
                      </v-chip> :مشتريات المنتجات الكلية
                  </h3>
              </v-alert> -->
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalPharmacySales + summary.totalDoctorSales + summary.totalRepositorySales)}}</h3>
                      </v-chip> :المبيعات
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency(summary.totalPharmacyDebts + summary.totalDoctorDebts + summary.totalRepositoryDebts)}}</h3>
                      </v-chip> :الديون
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalReceiptOfRepresentatives )}}</h3>
                      </v-chip> :الإستحصالات المستلمة من المندوبين
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalFinancialDues )}}</h3>
                      </v-chip> :ذمم المندوبين
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalSalaries )}}</h3>
                      </v-chip> :الرواتب
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalDoctorWalletUnPaid + summary.totalDoctorWalletPaid )}}</h3>
                      </v-chip> :دعم الاطباء الكلي
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="success">
                          <h3>{{$service.formatCurrency( summary.totalDoctorWalletPaid )}}</h3>
                      </v-chip> :دعم الاطباء المدفوع
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="error">
                          <h3>{{$service.formatCurrency( summary.totalDoctorWalletUnPaid )}}</h3>
                      </v-chip> :دعم الاطباء غير المدفوع
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalReturnedProducts )}}</h3>
                      </v-chip> :قيمة المنتجات المسترجعة
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalPaymentVouchers )}}</h3>
                      </v-chip> :سندات الصرف
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalExpenses )}}</h3>
                      </v-chip> :النثريات
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( summary.totalFMS )}}</h3>
                      </v-chip>
                       :FMS
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label color="primary">
                          <h3>{{$service.formatCurrency( ( summary.totalPharmacySales + summary.totalDoctorSales + summary.totalRepositorySales ) - summary.totalProductsPurchases - ( summary.totalDoctorWalletUnPaid + summary.totalDoctorWalletPaid ) - summary.totalSalaries - summary.totalExpenses - summary.totalPaymentVouchers - summary.totalFMS)}}</h3>
                      </v-chip> :الربح المتوقع
                  </h3>
              </v-alert>
  
              <v-alert text dense class="pa-2 mx-2">
                  <h3>
                      <v-chip label >
                          <h3>{{$service.formatCurrency( summary.totalReceiptOfRepresentatives - summary.totalProductsPurchases - summary.totalDoctorWalletPaid - summary.totalSalaries - summary.totalExpenses - summary.totalPaymentVouchers - summary.totalFMS )}}</h3>
                      </v-chip> :الربح الحقيقي
                  </h3>
              </v-alert>
  
          </v-container>
      </v-card>
  </div>
  </template>
  
  <script>
  export default {
  
      data() {
          return {
              filter: {
                search: '',
                from: '',
                to: '',
              },
              loading: false,
              menu: '',
              menu2: '',
              items: [],
              summary: {},
              headers: [{
                      text: 'الشهر',
                      value: 'date',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المصروفات',
                      value: 'totalExpenses',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي سندات الدفع (المدفوعات)',
                      value: 'totalPaymentVouchers',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المبيعات الصيدليات',
                      value: 'totalPharmacySales',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المبيعات الاطباء',
                      value: 'totalDoctorSales',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المبيعات المذاخر',
                      value: 'totalRepositorySales',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي ديلات الصيدليات',
                      value: 'totalPharmacyDealValue',
                      align: 'center'
                  },
                  {
                      text: 'عدد بونصات الصيدليات',
                      value: 'pharmacyBonusCount',
                      align: 'center'
                  },
                  {
                      text: 'عدد بونصات الاطباء',
                      value: 'doctorBonusCount',
                      align: 'center'
                  },
                  {
                      text: 'عدد بونصات المذاخر',
                      value: 'repositoryBonusCount',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي بونصات الصيدليات',
                      value: 'totalPharmacyBonus',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي بونصات الاطباء',
                      value: 'totalDoctorBonus',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي بونصات المذاخر',
                      value: 'totalRepositoryBonus',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي ديون الصيدليات',
                      value: 'totalPharmacyDebts',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي ديون الاطباء',
                      value: 'totalDoctorDebts',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي ديون المذاخر',
                      value: 'totalRepositoryDebts',
                      align: 'center'
                  },
                  {
                      text: ' اجمالي الرواتب',
                      value: 'totalSalaries',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المشتريات المنتجات',
                      value: 'totalProductsPurchases',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي مشتريات المنتجات المتوفره في المخزن',
                      value: 'totalProductsInWarehouse',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي غير المدفوعات للاطباء',
                      value: 'totalDoctorWalletUnPaid',
                      align: 'center'
                  },
                  {
                      text: 'اجمالي المدفوعات للاطباء',
                      value: 'totalDoctorWalletPaid',
                      align: 'center'
                  },
              ],
  
          }
      },
  
      mounted() {
          this.getItems()
      },
  
      methods: {

          async getItems() {
              this.loading = true
              try {
                  let res = await this.$http.get(`AccountsSummary/reportMonthly?startDate=${this.filter.from}&endDate=${this.filter.to}`)
                  console.log(res.data.result)
                  this.items = this.formatRecord(res.data.result)
                  this.getSummary()
              } catch (error) {
                  console.log(error)
                  this.$service.faild('حدث حطأ')
              } finally {
                  this.loading = false
              } 
            },
  
          async getSummary() {
              this.loading = true
              try {
                  let res = await this.$http.get(`AccountsSummary?startDate=${this.filter.from}&endDate=${this.filter.to}`)
                  console.log('res.data')
                  console.log(res.data.result)
                  this.summary = res.data.result
              } catch (error) {
                  console.log(error)
                  this.$service.faild('حدث حطأ')
              } finally {
                  this.loading = false
              }
          },
  
          formatRecord(items) { 
              let arr = items
              for (let i = 0; i < arr.length; i++) { //loop for all objects
                  let currentObject = Object.entries(arr[i]) // convert the current object to list
                  let newObject = currentObject // new variable to store the new object
                  for (let j = 0; j < currentObject.length; j++) { // loop for the current objectb after convert it to list
                      if (Number.isInteger(currentObject[j][1])) { // check if the current value is number to format it
                          newObject[j][1] = this.$service.formatCurrency(currentObject[j][1]);
                      } 
                    //   else if (!isNaN(Date.parse(new Date(currentObject[j][1])))) { // check if the value is date type
                    //       console.log(currentObject[j][1])
                    //       newObject[j][1] = currentObject[j][1].substring(0, 10)
                    //   } 
                      else {
                          newObject[j][1] = currentObject[j][1];
                      }
                  }
                  arr[i] = Object.fromEntries(newObject) // convert the list to object and set it to the original place
              }
              return arr;
          }
  
      },
  }
  </script>
  