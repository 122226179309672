<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="my-5">
        {{ $t('add') }} {{ $t('incentives') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading" ref="addIncentives">
          <v-row>
            <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      class="mt-1"
                      :disabled="allCompany"
                      outlined
                      
                      :label="$t('user')"
                      :no-data-text="$t('write-name-to-search')"
                      :items="users"
                      item-text="fullName"
                      v-model="inputs.userId"
                      
                      :search-input.sync="search"
                      item-value="id"
                      :loading="loading"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.fullName }}
                          <small class="mx-1">{{
                            data.item.userTypeName
                          }}</small>
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.fullName"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.userTypeName"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          hide-detials="auto"
                          outlined
                          v-model="inputs.date"
                          :label="$t('date')"
                          :rules="[(v) => !!v || $t('this-field-is-requird')]"
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="inputs.date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                      <!-- v-textfiled label=value -->
                      <v-text-field
                        hide-detials="auto"
                        outlined
                        v-model="inputs.value"
                        :label="$t('value')"
                        @input="inputs.value = $service.formatCurrency(inputs.value)"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-currency-usd"
                      ></v-text-field>
                  </v-col>
                  <!-- v-col 12 with textarea -->
                  <v-col cols="12">
                    <v-textarea
                      hide-detials="auto"
                      outlined
                      v-model="inputs.note"
                      :label="$t('note')"
                    ></v-textarea>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addIncentives">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {},
  data() {
    return {
      search: "",
      users: [],
      menu: false,
      loading: false,
      allCompany: false,
      inputs: {
        userId: "",
        note:"",
        value:0,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  created() {
    this.$http.get("/Users?" + this.search).then((res) => {
          this.users = res.data.data;
          this.loading = false;
        });
  },
  mounted() {},
  props: ["dialog"],
  methods: {
    async addIncentives() {
      var val = this.$refs.addIncentives.validate();

      if (val) {
        this.loading = true;
        // replace , with nothing in this.inputs.value
        this.inputs.value = this.inputs.value.replace(',', "");

        this.$http
          .post("/Incentives/post", this.inputs)
          .then(() => {
            this.dialog = false;
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message)
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.$refs.addIncentives.reset();
      this.$emit("updated", this.dialog);
    },
    search() {
      if (this.search !== "" && this.search !== null) {
        this.loading = true;
        this.$http.get("/Users?Search=" + this.search).then((res) => {
          this.users = res.data.data;
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>