<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('add-return') }}
        <v-spacer></v-spacer>
        <v-btn @click="close" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5">
        <v-form ref="addreturn" :loading="loading">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-chip-group
                    active-class="success"
                    v-model="selectedOrderType"
                  >
                    <v-chip filter label value="pharmacy">
                      {{ $t('Pharmacy Orders') }}
                    </v-chip>
                    <v-chip filter label value="doctor">
                      {{ $t('Doctor Orders') }}
                    </v-chip>
                    <v-chip filter label value="repository">
                      {{ $t('Repository Orders') }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    outlined
                    :label="$t('order')"
                    v-model="selectedOrderModel"
                    :items="orders"
                    :disabled="!selectedOrderType"
                    :search-input.sync="searchOrders"
                    item-value="id"
                    item-text="orderNO"
                    return-object
                    :loading="loading"
                  />
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :items="selectedProducts"
                    :headers="headers"
                    hide-default-footer
                  >
                    <template v-slot:item.action="{ item }">
                      <v-btn color="error" text @click="removeProduct(item)">
                        <f-icon icon="times-circle" />
                      </v-btn>
                      <v-btn color="primary" text @click="editProduct(item)">
                        <f-icon icon="edit" />
                      </v-btn>
                    </template>
                  </v-data-table>
                  <div class="d-flex justify-end mt-3">
                    <v-btn
                      :disabled="disabled"
                      @click="productDialog = true"
                      color="primary"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('add-one-product') }}
                    </v-btn>
                    <v-btn
                      :disabled="disabled"
                      @click="addAllProducts"
                      class="mx-2"
                      color="primary"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('add-all-products') }}
                    </v-btn>
                  </div>
                </v-col>
                <v-col>
                  <v-textarea
                    outlined
                    :label="$t('note')"
                    v-model="inputs.note"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-end mt-5">
          <v-btn large class="mx-2" outlined @click="close" color="error">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            large
            :disabled="selectedProducts.length == 0"
            @click="submit"
            color="success"
          >
            {{ $t('add') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="productDialog"
      persistent
      v-if="!disabled"
      :overlay="false"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('select-product') }}
          <v-spacer></v-spacer>
          <v-btn @click="productDialog = !productDialog" text color="error">
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form ref="addProduct" v-model="addProductForm">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  :label="$t('product')"
                  v-model="addProduct.productId"
                  :items="selectedOrder.products"
                  item-value="id"
                  item-text="product.tradeName"
                  :loading="loading"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Product is required']"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="qty"
                  v-if="!addProduct.productId"
                  :label="$t('return-qty')"
                  id="id"
                  outlined
                  disabled
                ></v-text-field>
                <v-text-field
                  name="qty"
                  v-if="addProduct.productId"
                  :label="$t('return-qty')"
                  id="id"
                  outlined
                  v-model="addProduct.qty"
                  :rules="[
                    (v) => !!v || $t('qty-is-required'),
                    (v) => v > 0 || $t('qty-must-be-greater-than-0'),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  :label="$t('note')"
                  v-model="addProduct.note"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <v-btn
                    class="mx-2"
                    outlined
                    @click="closeAddProduct"
                    color="error"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    :disabled="!addProductForm"
                    @click="addToSelectedProducts"
                    color="success"
                  >
                    {{ $t('add') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      inputs: {
        note: "",
      },
      addProduct: {
        productId: "",
        qty: 0,
        note: "",
      },
      productDialog: false,
      loading: false,
      orders: [],
      searchOrders: "",
      addProductForm: false,
      selectedOrder: {},
      selectedOrderModel: {},
      selectedOrderType: "",
      disabled: true,
      selectedProducts: [],
      headers: [
        {
          text: this.$t('product'),
          value: "product.tradeName",
        },
        {
          text: this.$t('quantity'),
          value: "returnQty",
        },
        {
          text: this.$t('total'),
          value: "qty",
        },
        {
          text: this.$t('note'),
          value: "returnNote",
        },
        {
          text: this.$t('actions'),
          value: "action",
        },
      ],
    };
  },
  methods: {
    close() {
      this.selectedProducts = [];
      this.selectedOrder = {};
      this.selectedOrderModel = {};
      this.selectedOrderType = "";
      this.disabled = true;
      this.dialog = false;
    },
    async submit() {
      try {
        this.loading = true;
        let data = {
          returnProducts: [],
          attachements: [],
          note: this.inputs.note,
        };
        this.selectedProducts.forEach((item, i) => {
          data.returnProducts.push({
            qty: item.returnQty,
            note: item.returnNote,
          });
          data.returnProducts[i][`${this.selectedOrderType}OrderProductId`] =
            item.id;
        });
        await this.$http.post(`/returns/post`, data);
        this.selectedProducts = [];
        this.selectedOrder = {};
        this.selectedOrderModel = {};
        this.selectedOrderType = "";
        this.disabled = true;
        this.dialog = false;
        this.$emit("refresh");
      } catch (e) {
        console.log(e);
        // this.$store.commit("UPDATE_SNACKBAR", true);
        // this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
        this.loading = false;
      }
    },
    removeProduct(item) {
      this.selectedProducts = this.selectedProducts.filter(
        (v) => v.id != item.id
      );
    },
    editProduct(item) {
      this.addProduct.productId = item.id;
      this.addProduct.qty = item.returnQty;
      this.addProduct.note = item.returnNote;
      this.productDialog = true;
    },
    addAllProducts() {
      this.selectedProducts = [];
      this.selectedOrder.products.forEach((item) => {
        this.selectedProducts.push({
          productId: item.id,
          returnQty: item.qty,
          returnNote: "",
          ...item,
        });
      });
    },
    closeAddProduct() {
      this.productDialog = false;
      this.$refs.addProduct.reset();
    },
    addToSelectedProducts() {
      let items = this.selectedProducts.filter(
        (v) => v.id == this.addProduct.productId
      );
      if (items.length > 0) {
        let index = this.selectedProducts.findIndex(
          (v) => v.id == this.addProduct.productId
        );
        this.selectedProducts[index].returnQty = this.addProduct.qty;
        this.selectedProducts[index].returnNote = this.addProduct.note;

        console.log(this.selectedProducts[index]);
      } else {
        this.selectedProducts.push({
          productId: this.addProduct.productId,
          returnQty: this.addProduct.qty,
          returnNote: this.addProduct.note,
          ...this.selectedOrder.products.find(
            (v) => v.id == this.addProduct.productId
          ),
        });
      }
      this.productDialog = false;
      this.$refs.addProduct.reset();
      this.$forceUpdate();
    },
    async getOrders(type, search = null) {
      try {
        this.loading = true;
        let filter = {};
        if (search != null) {
          filter.search = search;
        }
        let res = await this.$http.get(`/${type}Orders`, {
          params: filter,
        });
        this.orders = res.data.data;
        this.loading = false;
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog != true) {
        this.$refs.addreturn.reset();
      }
      this.$emit("updated", this.dialog);
    },
    selectedOrderType() {
      this.getOrders(this.selectedOrderType);
    },
    searchOrders() {
      this.getOrders(this.selectedOrderType, this.searchOrders);
    },
    async selectedOrderModel() {
      this.selectedProducts = [];
      this.loading = true;
      let res = await this.$http.get(
        `/${this.selectedOrderType}Orders/${this.selectedOrderModel.id}`
      );
      this.selectedOrder = res.data.result;
      this.loading = false;
      this.disabled = this.selectedOrder.id == undefined;
    },
  },
};
</script>
