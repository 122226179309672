var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('salary-calculation',{attrs:{"dialog":_vm.salaryCalculation},on:{"updated":function (_) {
        _vm.salaryCalculation = _;
      },"added":function (_) {
        _vm.salaryCalculation = _;
        _vm.getSalaries();
      }}}),_c('Pay',{attrs:{"dialog":_vm.paySalary},on:{"updated":function (_) {
        _vm.paySalary = _;
      },"added":function (_) {
        _vm.paySalary = _;
        _vm.getSalaries();
      }}}),_c('h1',{staticClass:"mb-3"},[_vm._v("Salaries")]),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v("Select User")]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"clearable":"","items":_vm.users,"search-input":_vm.searchUser,"loading":_vm.loading,"no-data-text":_vm.searchUser == '' && _vm.searchUser == null
              ? 'Write for search'
              : 'No data',"solo":"","item-value":"id","item-text":"fullName","label":"Select User"},on:{"update:searchInput":function($event){_vm.searchUser=$event},"update:search-input":function($event){_vm.searchUser=$event},"change":function($event){return _vm.getSalaries()}},model:{value:(_vm.options.UserId),callback:function ($$v) {_vm.$set(_vm.options, "UserId", $$v)},expression:"options.UserId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('select-company')))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"item-text":"name","label":_vm.$t('select-company'),"item-value":"id","loading":_vm.loading,"no-data-text":_vm.$t('no-data'),"solo":"","return-object":"","hide-details":"auto"},on:{"change":function($event){return _vm.getSalaries()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"st"}},[_vm._v("From Date")]),_c('v-menu',{ref:"menu",staticClass:"mt-2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-detials":"auto","solo":"","label":"From Date","prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.options.StartDate),callback:function ($$v) {_vm.$set(_vm.options, "StartDate", $$v)},expression:"options.StartDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{ref:"picker",model:{value:(_vm.options.StartDate),callback:function ($$v) {_vm.$set(_vm.options, "StartDate", $$v)},expression:"options.StartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"st"}},[_vm._v("To Date")]),_c('v-menu',{ref:"menu",staticClass:"mt-2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-detials":"auto","solo":"","label":"To Date","prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.options.EndDate),callback:function ($$v) {_vm.$set(_vm.options, "EndDate", $$v)},expression:"options.EndDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{ref:"picker",model:{value:(_vm.options.StartDate),callback:function ($$v) {_vm.$set(_vm.options, "StartDate", $$v)},expression:"options.StartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"transparent"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.salaryCalculation = !_vm.salaryCalculation}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Pay salary ")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.salaries,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.datePaid",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.datePaid)))+" ")]}},{key:"item.isPaid",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaid ? "Yes" : "No")+" ")]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }