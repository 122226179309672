<template>
  <div>
    <!-- use drawer -->
    <drawer :title="$t('accounts')" :items="drawer.items" icon="budget"> </drawer>
    <!-- use order -->

    <orders v-if="tab == 'orders'" />
    <forwarded v-if="tab == 'forwarded-orders'" />
    <!-- use order -->

    <order v-if="tab == 'order'" />
    <!-- use products -->
    <products v-if="tab == 'products'" />
    <!-- use returns -->
    <returns v-if="tab == 'returns'" />
    <!-- use return -->
    <return v-if="tab == 'return'" />
    <!-- use salaries -->
    <salaries v-if="tab == 'salaries'" />
    <!-- use incentives -->
    <incentives v-if="tab == 'incentives'" />
    <!-- use deductions -->
    <deductions v-if="tab == 'deductions'" />
    <!-- use Financial -->
    <financial v-if="tab == 'financial'" />
    <!-- use staudies -->
    <studies v-if="tab == 'studies'" />
    <!-- use products batches -->
    <products-batches v-if="tab == 'batches'" />
    <!-- use doctor-dues -->
    <doctor-dues v-if="tab == 'doctor-dues'" />
    <!-- use receipts -->
    <wallet v-if="tab == 'wallet'" />
    <expenses v-if="tab == 'expenses'" />
    <payment-vouchers v-if="tab == 'payment-vouchers'" />
    <accounts-summary v-if="tab == 'accounts-summary'" />
  </div>
</template>
<script>
// import drawer comonent
import Drawer from "@/layouts/Drawer";
// import orders component
import Orders from "./orders/Orders";
import Forwarded from "./forwarded-orders/Orders";
import Order from "./orders/Order";
// import products component
import Products from "./products/Products.vue";
//import returns component
import Returns from "./returns/Returns.vue";
import Return from "./returns/Return.vue";
import Salaries from "./stipend/salaries/Salaries.vue";
import Incentives from "./stipend/incentives/Incentives.vue";
// import stipend/deductions component
import Deductions from "./stipend/deductions/Deductions.vue";
// import stipend/financial component
import Financial from "./stipend/financial/Financial.vue";
// import studies component
import Studies from "./studies/Studies.vue";
// import ProductsBatches component
import ProductsBatches from "./products/ProductBatchs.vue";
// import DoctorDues component
import DoctorDues from "./doctor-dues/DoctorDues.vue";
// import receipts component
import Wallet from "./wallet/wallet-v2.vue";
import Expenses from "./expenses/Expenses.vue";
import PaymentVouchers from "./payment-vouchers/PaymentVouchers.vue";
import AccountsSummary from "./accounts-summary/AccountsSummary.vue";
export default {
  components: {
    Drawer,
    Orders,
    Forwarded,
    Order,
    Products,
    Returns,
    Salaries,
    Return,
    Incentives,
    Deductions,
    Financial,
    Expenses,
    Studies,
    ProductsBatches,
    DoctorDues,
    Wallet,
    PaymentVouchers,
    AccountsSummary,
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: this.$t('orders'),
            icon: "mdi-cash",
            children: [
              {
                title: this.$t('Pharmacy Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-orders",
                  params: { type: "PharmacyOrders" },
                },
              },
              {
                title: this.$t('Repository Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-orders",
                  params: { type: "RepositoryOrders" },
                },
              },
              {
                title: this.$t('Doctor Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-orders",
                  params: { type: "DoctorOrders" },
                },
              },
            ],
            access: this.$service.hideFrom((v) => [v == 9]),
          },
          {
            title: this.$t('forwarded-orders'),
            icon: "mdi-cash",
            children: [
              {
                title: this.$t('Pharmacy Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-forwarded-orders",
                  params: { type: "PharmacyOrders" },
                },
              },
              {
                title: this.$t('Repository Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-forwarded-orders",
                  params: { type: "RepositoryOrders" },
                },
              },
              {
                title: this.$t('Doctor Orders'),
                icon: "mdi-cash",
                route: {
                  name: "accounts-forwarded-orders",
                  params: { type: "DoctorOrders" },
                },
              },
            ],
            // access: this.$service.hideFrom((v) => [v != 9]),
          },
          {
            title: this.$t('returns'),
            icon: "mdi-autorenew",
            route: "accounts-returns",
          },
          {
            title: this.$t('sales-rep-wallet'),
            icon: "mdi-cash",
            route: "accounts-wallet",
            // children: [
            //   {
            //     title: this.$t('Pharmacy'),
            //     icon: "mdi-cash",
            //     route: {
            //       name: "accounts-wallet",
            //       params: { type: "PharmacyFinancialDues" },
            //     },
            //   },
            //   {
            //     title: this.$t('Repository'),
            //     icon: "mdi-cash",
            //     route: {
            //       name: "accounts-wallet",
            //       params: { type: "RepositoryFinancialDues" },
            //     },
            //   },
            //   {
            //     title:this.$t('Doctor'),
            //     icon: "mdi-cash",
            //     route: {
            //       name: "accounts-wallet",
            //       params: { type: "DoctorFinancialDues" },
            //     },
            //   },
            // ],
          },
          {
            title: this.$t('accounts-summary'),
            icon: "mdi-chart-areaspline",
            route: "accounts-summary",
          },
          {
            title: this.$t('products'),
            icon: "mdi-account-network",
            route: "accounts-products",
          },
          {
            title: this.$t('doctor-wallet'),
            icon: "mdi-wallet",
            route: "accounts-financial",
          },
          {
            title: this.$t('expenses'),
            icon: "mdi-cash",
            route: "accounts-expenses",
          },
          {
            title: this.$t('payment-vouchers'),
            icon: "mdi-cash-refund",
            route: "accounts-payment-vouchers",
          },
          {
            title: this.$t('salaries'),
            icon: "mdi-cash-multiple",
            children: [
              {
                title: this.$t('salaries'),
                route: "accounts-salaries",
              },
              {
                title: this.$t('incentives'),
                route: "accounts-incentives",
              },
              {
                title: this.$t('deductions'),
                route: "accounts-deductions",
              },
            ],
            access: this.$service.hideFrom((v) => [v == 9]),
          },
        ],
      },
    };
  },

  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined) {
      for (let i = 0; i < this.drawer.items.length; i++) {
        if (
          this.drawer.items[i].access == undefined ||
          this.drawer.items[i].access == true
        ) {
          if (this.drawer.items[i].children != undefined) {
            // if (this.drawer.items[i].children[i].access) {
            this.$router.push(
              typeof this.drawer.items[i].children[0].route == "string"
                ? {
                    name: this.drawer.items[i].children[0].route,
                  }
                : this.drawer.items[i].children[0].route
            );
            break;
            // }
          } else {
            this.$router.push(
              typeof this.drawer.items[i].route == "string"
                ? {
                    name: this.drawer.items[i].route,
                  }
                : this.drawer.items[i].route
            );
            break;
          }
        }
      }
    }
  },
  beforeMount() {
    this.$store.commit("TOGGLE_DRAWER", true);
  },
};
</script>
