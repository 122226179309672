<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="my-5">
        {{ $t("receipt") }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading" v-model="valid" ref="addReceipt">
          <v-row>
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                v-model="inputs.receiptAmount"
                :label="$t('receipt-amounts')"
                placeholder="0.0"
                :pattern="/^-?\d+\.?\d*$/"
                suffix="IQD"
                :rules="[
                  (v) => !!v || $t('this-field-is-requird'),
                  (v) =>
                    (v &&
                      v <= item[prefix][prefix.replace('Order', '')].debts) ||
                    $t('the-amount-is-more-than-the-debts'),
                ]"
                outlined
              ></v-text-field>
              {{ $t("total-price") }}: {{ item[prefix].totalPrice }}
              <br />
              {{ $t("current-debts") }} :
              {{ item[prefix][prefix.replace("Order", "")].debts }}
            </v-col>
            <!-- v-col 12 with textarea -->
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                v-model="inputs.cashDiscount"
                label="$t('cash-discount')"
                placeholder="0.0"
                suffix="IQD"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-radio-group
                row
                disabled
                v-model="inputs.fullObtained"
                :label="$t('full-obtained')"
              >
                <v-radio :label="$t('yes')" :value="true"></v-radio>
                <v-radio :label="$t('no-0')" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="addReceipt"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    item() {
      return this.$store.state.regions.region;
    },
  },
  data() {
    return {
      search: "",
      users: [],
      menu: false,
      valid: false,
      loading: false,
      allCompany: false,
      inputs: {
        fullObtained: true,
        receiptAmount: 0,
        cashDiscount: 0,
      },
    };
  },
  created() {},
  mounted() {},
  props: ["dialog", "type", "prefix"],
  methods: {
    async addReceipt() {
      var val = this.$refs.addReceipt.validate();
      this.type = this.type.replace("Orders", "FinancialDues");
      if (val) {
        this.loading = true;
        if (this.inputs.fullObtained == null) {
          this.inputs.fullObtained = true;
        }
        this.$http
          .post(`/${this.type}/receipt/${this.item.id}`, this.inputs)
          .then(() => {
            this.$emit("added", !this.dialog);
            this.dialog = false;
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    "inputs.receiptAmount"() {
      if (this.inputs.receiptAmount == this.item[this.prefix].totalPrice) {
        this.inputs.fullObtained = true;
      } else {
        this.inputs.fullObtained = false;
      }
    },
    dialog() {
      if (!this.dialog) this.$refs.addReceipt.reset();
      if (this.dialog) {
        this.inputs.fullObtained = true;
        this.prefix = this.prefix.replace(".", "");
        this.inputs.receiptAmount = this.item[this.prefix][
          this.prefix.replace("Order", "")
        ].debts;
        this.$refs.addReceipt.validate();
        console.log(this.item);
      }
      this.$emit("updated", this.dialog);
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
