var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"color":"transparent","flat":""}},[_c('v-card-title',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("sales-rep-wallet"))+" ")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-chip-group',{attrs:{"mandatory":"","active-class":"success"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.types),function(item){return _c('v-chip',{key:item.value,attrs:{"value":item.value,"color":"secondary","large":"","filter":"","label":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v("Select "+_vm._s(_vm.type)+" ")]),(_vm.type == 'Pharmacy')?_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.pharmacies,"search-input":_vm.searchPharmacy,"loading":_vm.loading,"no-data-text":_vm.searchPharmacy == '' && _vm.searchPharmacy == null
                  ? 'Write to search ...'
                  : 'No data..',"solo":"","filled":"","large":"","item-value":"id","chips":"","item-text":"fullName","label":'Select ' + _vm.type,"hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchPharmacy=$event},"update:search-input":function($event){_vm.searchPharmacy=$event}},model:{value:(_vm.options.pharmacyId),callback:function ($$v) {_vm.$set(_vm.options, "pharmacyId", $$v)},expression:"options.pharmacyId"}}):(_vm.type == 'Repository')?_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.repositories,"search-input":_vm.searchRepository,"loading":_vm.loading,"no-data-text":_vm.searchRepository == '' && _vm.searchRepository == null
                  ? 'Write to search ...'
                  : 'No data..',"solo":"","filleds":"","item-value":"id","large":"","chips":"","item-text":"fullName","label":'Select ' + _vm.type,"hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchRepository=$event},"update:search-input":function($event){_vm.searchRepository=$event}},model:{value:(_vm.options.repositoryId),callback:function ($$v) {_vm.$set(_vm.options, "repositoryId", $$v)},expression:"options.repositoryId"}}):_c('v-autocomplete',{attrs:{"hide-detials":"auto","clearable":"","chips":"","items":_vm.doctors,"search-input":_vm.searchDoctor,"loading":_vm.loading,"large":"","no-data-text":_vm.searchDoctor == '' && _vm.searchDoctor == null
                  ? 'Write to search ...'
                  : 'No data..',"solo":"","filleds":"","item-value":"id","label":'Select ' + _vm.type,"item-text":"fullName","hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchDoctor=$event},"update:search-input":function($event){_vm.searchDoctor=$event}},model:{value:(_vm.options.doctorId),callback:function ($$v) {_vm.$set(_vm.options, "doctorId", $$v)},expression:"options.doctorId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"se"}},[_vm._v("ٌRegion")]),_c('v-autocomplete',{attrs:{"label":_vm.$t('region'),"hide-details":"auto","chips":"","filled":"","solo":"","items":_vm.regions,"item-text":"name","item-value":"id","search-input":_vm.searchRegion},on:{"update:searchInput":function($event){_vm.searchRegion=$event},"update:search-input":function($event){_vm.searchRegion=$event}},model:{value:(_vm.options.regionId),callback:function ($$v) {_vm.$set(_vm.options, "regionId", $$v)},expression:"options.regionId"}})],1),_c('v-col'),_c('v-col',{attrs:{"cols":"4"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("select-representative"))+" ")]),_c('v-text-field',{attrs:{"solo":"","label":_vm.$t('search-representative'),"loading":_vm.loading,"hide-details":""},model:{value:(_vm.searchRepresentative),callback:function ($$v) {_vm.searchRepresentative=$$v},expression:"searchRepresentative"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('h3',[_vm._v("From")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","hide-details":"auto","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.options.StartDate),callback:function ($$v) {_vm.$set(_vm.options, "StartDate", $$v)},expression:"options.StartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"ar"},on:{"input":function (){_vm.getDues(), _vm.menu = false}},model:{value:(_vm.options.StartDate),callback:function ($$v) {_vm.$set(_vm.options, "StartDate", $$v)},expression:"options.StartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('h3',[_vm._v("To")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","hide-details":"auto","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.options.EndDate),callback:function ($$v) {_vm.$set(_vm.options, "EndDate", $$v)},expression:"options.EndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"ar"},on:{"input":function (){_vm.getDues(), _vm.menu2 = false}},model:{value:(_vm.options.EndDate),callback:function ($$v) {_vm.$set(_vm.options, "EndDate", $$v)},expression:"options.EndDate"}})],1)],1),_c('v-col',[_c('v-chip-group',{attrs:{"column":"","active-class":"success"},model:{value:(_vm.options.ReceivingUserId),callback:function ($$v) {_vm.$set(_vm.options, "ReceivingUserId", $$v)},expression:"options.ReceivingUserId"}},_vm._l((_vm.representatives),function(rep){return _c('v-chip',{key:rep.id,attrs:{"value":rep.id,"color":"secondary","large":"","filter":"","label":""}},[_vm._v(" "+_vm._s(rep.fullName)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.dues.length > 0)?_c('h3',[_vm._v("TOTAL RECEIPTS for current page: "),_c('span',{staticClass:"primary--text"},[_vm._v("( "+_vm._s(_vm.$service.formatCurrency(_vm.dues.map(function (item) { return item.receiptAmount; }).reduce(function (prev, next) { return prev + next; })))+" )")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items-per-page":_vm.options.PageSize,"headers":_vm.getHeaders(),"items":_vm.dues,"no-data-text":_vm.$t('no-data'),"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                  var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created), false))+" ")])]}},{key:"item.dateReceipt",fn:function(ref){
                  var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created), false))+" ")])]}},{key:"item.id",fn:function(ref){
                  var index = ref.index;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.receiptAmount",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$service.formatCurrency(item.receiptAmount)))])]}},{key:"item.no",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.zeroing",fn:function(ref){
                  var item = ref.item;
return [(!item.zeroing)?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.openConfirmDialog(item)}}},[_vm._v(_vm._s(_vm.$t("zeroing")))]):_c('v-btn',{attrs:{"color":"success","icon":"","large":""}},[_c('v-icon',[_vm._v("mdi-check-all")]),_vm._v(" "+_vm._s(_vm.$t("zeroed"))+" ")],1)]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-title',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.$t("confirm-zeroing"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.confirmDialog = !_vm.confirmDialog}}},[_c('f-icon',{attrs:{"icon":"times-circle"}})],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("are-you-sure-you-want-to-zeroing"))+" ")]),_c('v-card-actions',{staticClass:"my-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){_vm.confirmDialog = !_vm.confirmDialog}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.zeroing(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }