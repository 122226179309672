<template>
  <v-dialog v-model="dialog" width="800" height="800">
    <div v-if="valid">
      <iframe
        v-if="type == 'pdf' || type == 'docx'"
        :src="study.path"
        style="
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          border: none;
        "
      ></iframe>
      <v-card v-else>
        <v-card-title>
          <span class="headline">{{ study.name }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" color="error" text>
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-img :lazy-src="study.path" :src="study.path"></v-img>
        <!-- <v-card-text> -->
        <!-- </v-card-text> -->
      </v-card>
    </div>
    <v-card v-else>
      <v-card-title>
        <span class="headline">{{ $t("file-not-found") }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" color="error" text>
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Preview",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    study() {
      return this.$store.state.study;
    },
    type() {
      var sp = ["file"];
      if (this.$store.state.study.path != undefined) {
        sp = this.$store.state.study.path.split(".");
      }
      return sp[sp.length - 1];
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  watch: {
    dialog() {
      // this.$http
      //   .get(this.$store.state.study.path)
      //   .then(() => {
      //     this.valid = true;
      //   })
      //   .catch(() => {
      //     this.valid = false;
      //   });
      this.$emit("updated", this.dialog);
    },
  },
};
</script>
