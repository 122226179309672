<template>
  <div>
    <h1 class="mb-3">Doctor Dues</h1>
    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="4">
          <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-chip-group
            v-model="options.IsPaid"
            :loading="loading"
            active-class="success"
          >
            <v-chip label x-large filter :value="0"> All </v-chip>
            <v-chip label x-large filter :value="'true'"> Paid </v-chip
            ><v-chip label x-large filter :value="'false'"> Not Paid </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="finacialDues"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.created="{ item }">
                  {{ $service.formatDate(new Date(item.created)) }}
                </template>
                <template v-slot:item.isPaid="{ item }">
                  {{ item.isPaid ? "Paid" : "Not Paid" }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn @click="pay(item)" color="success" v-if="!item.isPaid">
                    <v-icon>mdi-check</v-icon>
                    Pay
                  </v-btn>
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      options: {
        Search: "",
        CompanyId: this.$store.state.company.id,
        OfficeId: this.$store.state.userInfo.office.id,
        PageIndex: 1,
        PageSize: 10,
        IsPaid: null,
      },
      total: 0,
      finacialDues: [],
      headers: [
        {
          text: "$t('amount')",
          value: "amount",
        },
        {
          text: "Paid",
          value: "isPaid",
        },
        {
          text: "Doctor Name",
          value: "doctor.fullName",
        },
        {
          text: "Doctor Spec",
          value: "doctor.specName",
        },
        {
          text: "Doctor Class",
          value: "doctor.className",
        },
        {
          text: "Invoice No.",
          value: "doctorOrder.orderNO",
        },
        {
          text: this.$t('created'),
          value: "created",
        },
        {
          text: this.$t('actions'),
          value: "action",
        },
      ],
    };
  },
  methods: {
    getFinacial() {
      this.loading = true;
      if (this.options.IsPaid == "0" || this.options.IsPaid == 0)
        this.options.IsPaid = null;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/DoctorWallets" + filter.replace("?&", "?"))
        .then((finacialDues) => {
          this.finacialDues = finacialDues.data.data;
          for (let i = 0; i < this.finacialDues.length; i++) {
            Object.keys(this.finacialDues[i]).forEach((key) => {
              if (typeof this.finacialDues[i][key] == "number") {
                this.finacialDues[i][key] =
                  "IQD " +
                  this.$service.formatCurrency(this.finacialDues[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    pay(item) {
      this.$http
        .delete("/DoctorWallets/paid/" + item.id)
        .then(() => {
          this.getFinacial();
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getFinacial();
      },
      deep: true,
    },
  },
  created() {
    this.getFinacial();
  },
};
</script>
