var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('deductions')))]),_c('add',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        this$1.dialog = _;
        _vm.getDeductions();
      }}}),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('company')))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"item-text":"name","item-value":"id","loading":_vm.loading,"no-data-text":_vm.$t('no-data'),"solo":"","return-object":"","hide-details":"auto"},on:{"change":function($event){return _vm.getDeductions()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"loading":_vm.loading,"active-class":"success"},on:{"change":function($event){return _vm.getDeductions()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.statues),function(item,index){return _c('v-chip',{key:index,attrs:{"large":"","filter":"","value":item.key}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"transparent"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('deductions'))+" ")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.deductions,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }